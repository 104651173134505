<template>
	<span class="">
		<slot name="activator" :toggle="toggle"></slot>

		<smart-dialog title="Ajouter un dossier" v-model="dialog" transition="dialog-bottom-transition" width="700">
				<v-form v-model="form.valid" ref="form" v-on:submit.prevent="createBooking" class="pt-2">
					<v-row no-gutters class="ma-3" >
						<v-col cols="12" class="grey--text mt-3 mb-2 pl-1">
							Dossier
						</v-col>
						<v-col cols="4">
							<v-select
								v-model="form.siteId"
								:rules="rules.nonEmpty()"
								:items="data.sites"
								item-text="name"
								item-value="id"
								label="Site"
								class="pa-1"
							></v-select>
						</v-col>
						<v-col cols="4">
							<v-select
								v-model="form.priceClassTypeId"
								:rules="rules.nonEmpty()"
								:items="data.priceclass"
								item-text="name"
								item-value="id"
								label="Classe de prix"
								class="pa-1"
							></v-select>
						</v-col>
						<v-col cols="4">
							<v-select
								v-model="form.currencyCode"
								:rules="rules.nonEmpty()"
								:items="data.currencies"
								item-text="name"
								item-value="code"
								label="Vente"
								class="pa-1"
							></v-select>
						</v-col>

						<v-col cols="12" class="grey--text mb-2 pl-1">
							Client
						</v-col>
						<v-col cols="4">
							<v-select
								v-model="form.countryCode"
								:rules="rules.nonEmpty()"
								:items="data.countries"
								item-text="name"
								item-value="code"
								label="Pays"
								class="pa-1"
							></v-select>
						</v-col>
						<v-col cols="4">
							<v-select
								v-model="form.langId"
								:rules="rules.nonEmpty()"
								:items="data.langs"
								item-text="name"
								item-value="id"
								label="Langue"
								class="pa-1"
							></v-select>
						</v-col>
						<v-col cols="4">
							<v-select
								v-model="form.declarations"
								:items="data.declarations"
								label="PACS / Voyage de noces"
								class="pa-1"
								clearable multiple
								@change="declarationsChange"
							></v-select>
						</v-col>

						<v-col cols="12" class="grey--text mt-3 mb-2 pl-1">
							Voyageurs
						</v-col>
						<v-col cols="12">
							<booking-guest-creation v-model="form.guests"
																			:booking-status="defaultBookingStatus"></booking-guest-creation>
						</v-col>

					</v-row>
					<v-card-actions class="actions-btn">
						<v-spacer></v-spacer>
						<smart-btn primary type="submit" :disabled="!form.valid">Créer</smart-btn>
					</v-card-actions>
				</v-form>

		</smart-dialog>
	</span>

</template>

<script>
	import BookingGuestCreation from "./BookingGuestCreation";
	import {get, post} from "@/utils/api"
	import FormMixin from "../mixins/FormMixin";
	import {EventBus} from "@/components/commons/event-bus";

	export default {
		name: "BookingAdd",
		components: {BookingGuestCreation},
		mixins: [FormMixin],
    props:{
      afterCreationFn: {type: Function,required:false, default:() => Promise.resolve()}
    },
		data() {
			return {
				dialog: false,
				defaultBookingStatus: 'quotation',

				data: {
					sites: [],
					priceclass: [],
					langs: [
						{id: 1, locale: "fr_FR", name: 'Français'},
						{id: 4, locale: "it_IT", name: 'Italien'}
					],
					countries: [
						{code: 'FR', name: 'France'},
						{code: 'RE', name: 'Réunion'},
						{code: 'IT', name: 'Italie'}
					],
					currencies: [
						{id: 1, code: "EUR", name: 'Euros'}
					],
					declarations: ['Voyage de Noces', 'PACS']
				},

				form: {
					siteId: null,
					langId: 1,
					priceclassTypeId: null,
					countryCode: "FR",
					currencyCode: "EUR",
					declarations: [],
					guests: [],
					valid: false
				},
			}
		},
		created() {

		},
		watch: {
			dialog: {
				immediate: true, handler(newVal) {
					if (newVal && (this.data.sites.length == 0 || this.data.priceclass.length == 0))
						this.init()
				}
			},
			'form.siteId'() {
				if (this.form.siteId == 4) {
					this.form.countryCode = "RE"
				}
			}
		},
		computed: {},
		methods: {
			init() {
				get('/api/search/priceclasstypes')
					.json((priceclass) => {
						this.data.priceclass = priceclass
					})
				get("/api/search/sites")
					.json((sites) => {
						this.data.sites = sites
					})
			},
			toggle() {
				this.dialog = !this.dialog
			},
			declarationsChange() {
				if (this.form.declarations.length > 1) {
					this.form.declarations = this.form.declarations.slice(-1)
				}
			},
			createBooking() {
				if (this.$refs.form.validate()) {
					post('/api/booking/create', this.form)
						.badRequest(err => {
							EventBus.$emit('toaster-msg', err)
						})
						.text(async (data) => {
              await this.afterCreationFn(data)
              this.$router.push({name: 'Booking', params: {id: data}});
						})
				}
			},
		}
	}
</script>

<style scoped>

</style>
