<template>
  <div>
    <p class="mb-0 headline text-center">{{ title }}</p>
    <div class="d-flex align-center justify-center">
      <v-icon
          v-for="(star, i) in 4"
          :key="i"
          small
         :color="i + 1 <= average || setStars(i,average) == 'mdi-star-half-full' ? 'yellow darken-2' : 'white'"
      >{{ setStars(i,average) }}</v-icon
      >
      <p class="mb-0 ml-4 body-1">{{count}} avis</p>
    </div>
  </div>
</template>
<script>
export default {
  name:"AverageItem",
  props:{
    average:{type:Number,required:true},
    count:{type:Number,required:true},
    title:{type:String ,required:true}
  },
  methods:{
    setStars(i, average) {
      const roundedAverage = Math.floor(average)
      const decimalPart = average - roundedAverage
      if (decimalPart >= 0.5 && decimalPart < 1 && i + 1 === roundedAverage + 1) {
        return "mdi-star-half-full"
      }
      return i + 1 <= roundedAverage ? "mdi-star" : "mdi-star-outline";
    }
  }
}
</script>