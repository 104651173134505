<template>
  <v-card ref="vCardRef" :elevation="1" style="height:100%; width:100%; overflow: hidden " class="pa-4">
    <div class="d-flex flex-row justify-space-between mb-8">
      <span class="mr-10 font-weight-bold">
        {{ conf.title }}</span>
      <div>
        <span @click="viewBy('day')"
              :class="actualView == 'day'
                ? 'font-weight-black pointer-event-none'
                : 'text-decoration-underline cursor-pointer'">Jour</span> |
        <span @click="viewBy('week')"
              :class="actualView == 'week'
                ? 'font-weight-black pointer-event-none'
                : 'text-decoration-underline cursor-pointer'">Semaine</span> |
        <span @click="viewBy('month')"
              :class="actualView == 'month'
              ? 'font-weight-black pointer-event-none'
              : 'text-decoration-underline cursor-pointer'">Mois</span>
      </div>
    </div>
    <apexchart
        type="area"
        :height="series.length > 0 ? chartHeight : null"
        :options="options"
        :series="series"
        v-if="series.length > 0"
        style="height:auto"
    ></apexchart>
  </v-card>
</template>
<script>
import {post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";
import {ref} from "vue";
import _ from "lodash";
import {getDayOfYear, getDaysInYear} from "date-fns";
//Transformation Panier en Devis en Résas
export default {
  name: "RateCartToBookChart",
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}
  },

  data() {
    return {
      actualView: null,
      tmpSeries: [],
      series: [],
      vCardRef: ref(null),
      data: [],
      url: `/api/widget/dashboard/rate/cart/book`,
      options: {
        chart: {
          toolbar: {
            show: false
          },
          type: "area",
          stacked: false,
          height: null,
        },

        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        fill: {
          opacity: 1,
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.45,
            opacityTo: 0.05
          }
        },
        colors: [],
        legend: {
          position: "top",
          horizontalAlign: "left"
        },
        xaxis: {
          type: "datetime"
        },
        yaxis: {logarithmic: false,}
      },
    }
  },
  created() {
    EventBus.$on('editMode', () => this.updateSize())
    EventBus.$on('searchDashboard', () => this.init());
    this.init();
  },
  computed: {
    chartHeight() {
      return this.vCardRef != null ? `${this.$refs.vCardRef.$el.clientHeight - 100}px` : 'auto';
    },
    labels() {
      return Object.keys(this.grouped)
    },

  },
  mounted() {
    this.vCardRef = this.$refs.vCardRef.$el.clientHeight
    if (this.conf.chartOptions == null) {
      this.conf.chartOptions = this.options
      delete this.conf.chartOptions.xaxis.min
      delete this.conf.chartOptions.xaxis.max
    } else {
      this.options = {...this.options, ...this.conf.chartOptions}
    }
  },

  methods: {
    updateSize() {
      this.vCardRef = this.$refs.vCardRef != null ? this.$refs.vCardRef.$el.clientHeight : 0
    },
    init() {
      this.series = []
      let periods = null
      if (this.conf.data.act.type != null && this.conf.data.old.type != null) {
        periods = {act: this._calculateDates(this.conf.data.act), old: this._calculateDates(this.conf.data.old)}
      }
      const formProps = JSON.parse(JSON.stringify(this.form))
      const searchForm = {
        startDateAct: periods != null ? periods.act.start : formProps.startDateAct,
        stopDateAct: periods != null ? periods.act.stop : formProps.stopDateAct,
        startDateOld: periods != null ? periods.old.start : formProps.startDateOld,
        stopDateOld: periods != null ? periods.old.stop : formProps.stopDateOld,
        destinations: this.conf.data.destinations.length > 0 ? this.conf.data.destinations : formProps.destinations,
        sites: this.conf.data.sites.length > 0 ? this.conf.data.sites : formProps.sites,
        commercialIds: [],
        allAgencies: false
      }
      post(this.url, searchForm, {"Content-Type": "application/json"})
          .json(rs => {
            this.tmpSeries = []
            let rawDatas = rs.filter(r => r.name !== "option" && r.name !== "deleted")
            rawDatas.forEach(serie => {
              this.tmpSeries.push({...serie, data: serie.data.map(d => [d.date, d.count])})
            })
            if (this.conf.view != null) {
              switch (this.conf.view) {
                case 'month':
                  this.viewBy('month')
                  this.actualView = "month"
                  break;
                case "day":
                  this.viewBy('day')
                  this.actualView = "day"
                  break;
                case "week" :
                  this.viewBy('week')
                  this.actualView = "week"
                  break;
              }
            } else {
              this.viewBy('week')
            }
          })
    },
    chartByMonth() {
      let seriesTmp = []
      const r = JSON.parse(JSON.stringify(this.tmpSeries))
      const refYear = new Date(this.form.startDateOld).getFullYear()
      r.forEach(raw => {
        seriesTmp.push({name: raw.name, data:this.groupByMonth(raw.data, refYear)})
      })
      this.series[0] = seriesTmp.find(s => s.name == "cancelled")
      this.series[1] = seriesTmp.find(s => s.name == "booked")
      this.series[2] = seriesTmp.find(s => s.name == "quotation")
      this.series[3] = seriesTmp.find(s => s.name == "cart")
    },
    chartByDay() {
      let seriesTmp = []
      const r = JSON.parse(JSON.stringify(this.tmpSeries))
      r.forEach(raw => {
        seriesTmp.push({name: raw.name, data:raw.data})
      })
      this.series[0] = seriesTmp.find(s => s.name == "cancelled")
      this.series[1] = seriesTmp.find(s => s.name == "booked")
      this.series[2] = seriesTmp.find(s => s.name == "quotation")
      this.series[3] = seriesTmp.find(s => s.name == "cart")
    },
    chartByWeek() {
      let seriesTmp = []
      const r = JSON.parse(JSON.stringify(this.tmpSeries))
      const refYear = r[0].data.length > 0 ? r[0].data[0][0].split("-")[0] : new Date().getFullYear()

      r.forEach(raw => {
        seriesTmp.push({name: raw.name, data:this.groupByWeek(raw, refYear)})
      })
      this.series[0] = seriesTmp.find(s => s.name == "cancelled")
      this.series[1] = seriesTmp.find(s => s.name == "booked")
      this.series[2] = seriesTmp.find(s => s.name == "quotation")
      this.series[3] = seriesTmp.find(s => s.name == "cart")
    },
    groupByMonth(list, refYear) {
      const yearData = _.groupBy(list, e => {
        return this._parseAndFormatDate(e[0], "yyyy-MM-dd", 'MM')
      })
      return Object.keys(yearData).map((key) => {
        const date = this._parseDate(yearData[key][0][0], "yyyy-MM-dd")
        date.setFullYear(refYear)
        return [date, _.sumBy(yearData[key], (v) => v[1])]
      }).sort((a, b) => {
        return a[0] - b[0]
      })
    },
    groupByWeek(list, refYear) {
      const yearData = _.groupBy(list.data, e => {
        const d = new Date(e[0])
        return Math.ceil(
            (getDayOfYear(d) * 52) / getDaysInYear(d)
        )
      })
      let result =  Object.keys(yearData).map((key) => {
        const date = this._parseDate(yearData[key][0][0], "yyyy-MM-dd")
        date.setFullYear(refYear)
        return [date, _.sumBy(yearData[key], (v) => {
          return v[1]
        } )]
      }).sort((a, b) => {
        return a[0] - b[0]
      })
      return result
    },


    viewBy(timeUnit) {
      //this.options.tooltip.x.format = null
      this.series = []
      this.actualView = timeUnit;
      switch (timeUnit) {
        case 'day': {
          //this.options.tooltip.x.format = 'dd MMMM'
          this.chartByDay()
          break;
        }
        case 'month': {
          //this.options.tooltip.x.format = 'MMMM'
          this.chartByMonth()
          break;
        }
        case 'week': {
          //this.options.tooltip.x.format = 'dd MMMM'
          this.chartByWeek()
          break;
        }

      }
    },
  }
}
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.pointer-event-none {
  pointer-events: none;
}
</style>