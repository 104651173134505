<template>
  <prestation kind="prestationgroup" :booking="booking" :prestation="prestationWithDetails" :index="index"
              :loading="disablePanel" :renderfab="renderfab">
    <div slot="header-title">
      <div class="subtitle-1 font-weight-medium">
        {{ prestation.route.fromName }}
        <v-icon small>arrow_right_alt</v-icon>
        {{ prestation.route.toName }}
        ({{ prestation.route.segments[0].airlineCode }}
        {{ prestation.route.segments[0].flightNumber }})
        <span class="ml-4 grey--text text--lighten-1 caption">{{ getTravelClasses(prestation) }} -
          {{ stopovers }} escale(s)</span>
      </div>
      <span>
        <v-chip label small outlined :href="sourceLink" target="_blank">
          {{ prestation.source }} <v-icon v-if="sourceLink" x-small class="ml-1">north_east</v-icon>
        </v-chip>
      </span>

      <template v-if="prestation.status == 'booked' && !prestation.pnr">
        <v-icon color="warning">warning</v-icon>
        Attention aucun PNR rattaché à
        cette prestation !
        <v-icon color="warning">warning</v-icon>
      </template>

      <template v-else-if="prestation.pnr">
        <v-chip slot="activator" label small color="light-blue darken-2" class="mx-1" outlined>
          <span>
            PNR:
            <b>{{ prestation.pnr }}</b>
          </span>
        </v-chip>
      </template>

      <template v-if="prestation.emitted">
        <v-chip slot="activator" label small color="light-blue darken-2" class="mx-1" outlined>
          <span>
            Emis
            <span v-if="prestation.emittedDate">
              le:
              <b>{{ prestation.emittedDate }}</b>
            </span>
          </span>
        </v-chip>
      </template>
      <template v-else-if="prestation.expirationDate">
        <v-chip slot="activator" label small color="light-blue darken-2" class="mx-1" outlined>
          <span>
            Expiration:
            <b>{{ prestation.expirationDate }}</b>
          </span>
        </v-chip>
      </template>

      <div v-if="prestation.pricechanged">
        <v-icon color="warning">warning</v-icon>
        Attention les prix ont changés
        lors de la demande Amadeus.
        <br/>
        <v-icon color="warning">warning</v-icon>
        Veuillez confirmer le nouveau
        prix et valider la prestation
      </div>

      <!-- <availability :kind="prestation.availability"/>-->
    </div>

    <div slot="header-status">
      <availability v-model="prestation.status" kind="BookingStatus"/>
      <availability v-model="prestation.supplierStatus" kind="SupplierStatus"/>
    </div>

    <div slot="menu-top">
      <v-list-item @click="editDialog = !editDialog">
        <v-list-item-title>
          <v-icon small>mdi-swap-horizontal</v-icon>
          {{ $t("modify-flights") }}
        </v-list-item-title>
      </v-list-item>

      <!-- <v-list-item @click="">
				<v-list-item-title> {{$t("modify-places")}}</v-list-item-title>
			</v-list-item>
			-->
      <v-divider></v-divider>
      <v-list-item :disabled="prestation.pnr !== null" @click="bookFlight">
        <v-list-item-title>
          <v-icon small>mdi-folder-plus-outline</v-icon>
          {{ $t("create-pnr") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item :disabled="!prestation.pnr" @click="maj">
        <v-list-item-title>
          <v-icon small>mdi-folder-sync-outline</v-icon>
          {{ $t("force-maj") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item :disabled="!prestation.pnr" @click="detachPnr">
        <v-list-item-title>
          <v-icon small>mdi-folder-alert-outline</v-icon>
          {{ $t("detach-pnr") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item :disabled="!prestation.pnr" @click="cancelPnr">
        <v-list-item-title>
          <v-icon small>mdi-folder-remove-outline</v-icon>
          {{ $t("cancel-pnr") }}
        </v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item :disabled="!prestation.pnr || prestation.tickets.length > 0" @click="issueFlight">
        <v-list-item-title>
          <v-icon small>mdi-folder-upload-outline</v-icon>
          {{ $t("issue-ticket") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item disabled>
        <!-- not implemented -->
        <v-list-item-title>
          <v-icon small>mdi-account-card-details-outline</v-icon>
          {{ $t("send-e-ticket") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item :disabled="!prestation.pnr" @click="dialogCreateTicket.show = true">
        <v-list-item-title>
          <v-icon small>mdi-account-card-details-outline</v-icon>
          {{ $t("create-e-ticket") }}
        </v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
    </div>

    <div slot="content">
      <v-row v-if="prestation.oldPrestationGroup" class="align-center">
        <v-col cols="8" class="pa-1 elevation-1 grey-lighten-5" style="background: #fafafa">
          <v-icon size="14">mdi-timer-sand</v-icon>
          Remplace la prestation
          {{ prestation.oldPrestationGroup.route.toName }}
          ({{ prestation.oldPrestationGroup.route.segments[0].airlineCode }}
          {{ prestation.oldPrestationGroup.route.segments[0].flightNumber }})
          <availability v-model="prestation.oldPrestationGroup.status" kind="BookingStatus"/>
          <availability v-model="prestation.oldPrestationGroup.supplierStatus" kind="SupplierStatus"/>

          <b>{{
              prestation.oldPrestationGroup.price
                  | currency(prestation.oldPrestationGroup.currency)
            }}</b>

          <v-icon size="14" @click="showOldPrestationGroup = !showOldPrestationGroup">mdi-eye</v-icon>
        </v-col>
      </v-row>

      <smart-dialog v-model="editDialog" fullscreen hide-overlay transition="dialog-bottom-transition"
                    :title="$t('modify-flights')">
        <template>
          <add-flight-v2 :booking="booking" :flight-selected="prestation" :sumPrestation="addPrestationRecap"
                      :lastPrestationDate="prestation.start"></add-flight-v2>
        </template>

        <v-btn dark icon absolute top right style="top: 6px; right: 70px" @click="addPrestationRecap = true">
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>
      </smart-dialog>

      <smart-dialog v-if="prestation.oldPrestationGroup" v-model="showOldPrestationGroup" :width="900"
                    :title="prestation.oldPrestationGroup.name">
        <prestation-flight :booking="booking" :prestation="prestation.oldPrestationGroup" :index="0"
                           :renderfab="false"></prestation-flight>
      </smart-dialog>

      <smart-dialog v-if="dialogCreateTicket.show" v-model="dialogCreateTicket.show" :width="700"
                    title="Ajouter un ticket">
        <v-row dense class="pa-4">
          <v-col cols="12">
            <v-select v-model="dialogCreateTicket.value.prestationGuestId" :items="guests" label="Passager" class="pa-1"
                      item-value="id">
              <template #item="{ item }">{{ item.firstName }} {{ item.lastName }}</template>
              <template #selection="{ item }">{{ item.firstName }} {{ item.lastName }}</template>
            </v-select>
          </v-col>
          <v-col cols="3">
            <v-select v-model="dialogCreateTicket.value.ticketType" :items="dialogCreateTicket.ticketsTypes"
                      label="Type de ticket" class="pa-1"></v-select>
          </v-col>
          <v-col cols="3">
            <v-select v-model="dialogCreateTicket.value.ticketStatus" :items="dialogCreateTicket.ticketsStatus"
                      label="Status du ticket" class="pa-1"></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="dialogCreateTicket.value.ticketNumber" label="Numéro du ticket" class="pa-1"
                          hint="Sans espaces" persistent-hint required></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-spacer></v-spacer>
          <v-col cols="4" class="mr-4 mb-2">
            <smart-btn primary block class="pr-4" :loading="dialogCreateTicket.loading"
                       @click.native="createFlightTicket">Enregistrer
            </smart-btn>
          </v-col>
        </v-row>
      </smart-dialog>
    </div>

    <template slot="tabs">
      <v-tab key="segments" ripple>
        <span>
          <v-icon small class="icon-segment">mdi-ray-start-end</v-icon>Segments
        </span>
      </v-tab>
      <v-tab-item key="segments" :transition="false" :reverse-transition="false">
        <v-card>
          <v-card-text>
            <flight-segment :fly="buildFlyForSegment(prestation.route)" class="elevation-0"></flight-segment>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </template>

    <template slot="tabs">
      <v-tab key="otherFlies" ripple>
        <span>
          <v-icon small>flights</v-icon>Autres Vols
        </span>
      </v-tab>
      <v-tab-item key="otherFlies" :transition="false" :reverse-transition="false"
                  style="overflow: scroll; max-height: 300px">

        <v-list-item v-for="(flight) in otherFlies" :key="flight.id">
          <v-list-item-content class="py-1 " style="border-bottom: 1px dotted lightgrey">
            <div class="subtitle-1 font-weight-medium d-flex flex-row justify-space-between">
              <div class="d-flex flex-row">
                <div class="d-flex flex-column ">
                  <span class="">{{ flight.flies[flightIndex(flight.flies)].from.code }}</span>
                  <span class="caption text--lighten-1">{{ flight.flies[flightIndex(flight.flies)].departureDate }}</span>
                </div>
                <v-icon small class="mx-4">arrow_right_alt</v-icon>
                <div class="d-flex flex-column ">
                    <span>{{ flight.flies[flightIndex(flight.flies)].to.code }} ({{
                        flight.flies[flightIndex(flight.flies)].segments[0].marketingAirline.code
                      }}
                {{ flight.flies[flightIndex(flight.flies)].segments[0].flightNumber }})</span>
                  <span class="caption text--lighten-1">{{ flight.flies[flightIndex(flight.flies)].departureDate }}</span>
                </div>
                <span class="ml-2 mt-3 text--lighten-1 caption">{{ flight.flies[flightIndex(flight.flies)].travelClass }} -
                  {{ getStopoverOtherFlies(flight.flies[flightIndex(flight.flies)]) }} • Durée du trajet: {{
                    flightDuration(flight.flies[flightIndex(flight.flies)])
                  }}
                </span>
              </div>
              <span>{{ _formatCurrency(flight.prices[0].pricing.price, 'EUR') }}</span>
            </div>
            <v-divider></v-divider>
          </v-list-item-content>
        </v-list-item>
      </v-tab-item>
    </template>

    <template slot="tabs" v-if="prestation.tickets.length > 0">
      <v-tab key="Tickets" ripple>
        <span>
          <v-icon small class="icon-segment">mdi-ticket-confirmation</v-icon>Tickets
        </span>
      </v-tab>
      <v-tab-item key="tickets" :transition="false" :reverse-transition="false">
        <v-card>
          <v-card-text>
            <flight-tickets :ticketsList="prestation.tickets"></flight-tickets>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </template>

    <template slot="tabs" v-if="competitors">
      <v-tab key="Competitors" ripple>
        <span>
          <v-icon small>mdi-ab-testing</v-icon> Concurence
        </span>
      </v-tab>
      <v-tab-item key="Competitors" :transition="false" :reverse-transition="false">
        <v-card flat>
          <v-card-text>
            <booking-price-computing :booking="booking" :prestation="prestation"/>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col>
                <prestation-flight-competitor :prices="competitors.same" title="Vols identiques"/>
              </v-col>
              <v-col>
                <prestation-flight-competitor :prices="competitors.best" title="Vols les moins cher"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

      </v-tab-item>
    </template>
  </prestation>
</template>

<script>
import BookingPriceComputing from "@/components/commons/BookingPriceComputing";
import CollapsePanel from "@/components/commons/CollapsePanel";
import GuestsMixin from "@/components/mixins/GuestsMixin";
import FlightSegment from "@/components/booking/flight/FlightSegment";
import FlightTickets from "@/components/booking/flight/FlightTickets";
import PrestationFlightCompetitor from "@/components/booking/PrestationFlightCompetitor";
import AddFlightV2 from "@/components/booking/flight/AddFlightV2";
import {EventBus} from "@/components/commons/event-bus";
import Availability from "@/components/commons/Availability";
import GuestList from "@/components/commons/GuestList";
import PrestationMixin from "@/components/mixins/PrestationMixin";
import Prestation from "@/components/booking/Prestation";
import {get, post} from "@/utils/api";
import BookingDrawerContent from "../commons/BookingDrawerContent";

export default {
  name: "PrestationFlight",
  props: ["booking", "prestation", "index", "renderfab"],
  mixins: [PrestationMixin, GuestsMixin],
  components: {
    BookingPriceComputing,
    BookingDrawerContent,
    Availability,
    AddFlightV2,
    FlightSegment,
    FlightTickets,
    Prestation,
    CollapsePanel,
    GuestList,
    PrestationFlightCompetitor
  },
  watch: {
    prestation() {
      this.getFlightPriceDetails();
    },
  },
  created() {
    this.getStopoverCount();
    this.getFlightPriceDetails();
    this.getOtherFlies()
    EventBus.$on("flight-edited", () => {
      this.editDialog = false;
    });
    this.getCompetitorsPrice();
  },
  destroyed: function () {
    EventBus.$off("flight-edited");
  },
  data() {
    return {
      showOldPrestationGroup: false,
      addPrestationRecap: true,
      panel: 1, //default open,
      guests: this.prestation.guests,
      stopovers: 0,
      editDialog: false,
      prestationWithDetails: this.prestation,
      competitors: null,
      otherFlies: [],
      dialogCreateTicket: {
        loading: false,
        show: false,
        ticketsTypes: [
          "Electronic",
          "Paper",
          "Manual",
          "Manual_electronic",
          "Manual_paper",
          "Unknown",
        ],
        ticketsStatus: ["Ticketed", "Cancelled", "Refunded", "Unknown"],
        value: {
          ticketType: "Electronic",
          ticketStatus: "Ticketed",
          prestationGuestId: null,
          ticketNumber: null,
        },
      },
    };
  },
  computed: {
    sourceLink(){
      if (this.prestation.source == "AIRGATEWAY" && this.prestation.sourceReference){
        return 'https://web.bookingpad.app/orders/' + this.prestation.sourceReference
      }
      return null
    }
  },
  methods: {
    buildFlyForSegment(route) {
      const fly = {
        segments: route.segments.map((segment) => {
          return {
            duration: segment.duration,
            departureDate: segment.start,
            arrivalDate: segment.stop,
            airplane: {
              maker: segment.aircraft.maker,
              model: segment.aircraft.model,
              variant: segment.aircraft.variant,
              airlinecode: segment.airlineCode,
              flightNumber: segment.flightNumber,
            },
            from: {
              code: segment.fromCode,
              name: segment.fromName,
              terminal: "",
            },
            to: {
              code: segment.toCode,
              name: segment.toName,
              terminal: "",
            },
            marketingAirline: {
              code: segment.marketingAirlinecode,
              name: segment.marketingAirlinename,
            },
            operatingAirline: {
              code: segment.operatingAirlinecode,
              name: segment.operatingAirlinename,
            },
            validatingAirline: {
              code: segment.validatingAirlinename,
              name: segment.validatingAirlinename,
            },
            travelClass: segment.travelClass,
            passengerDetails: segment.details,
            flyStops: segment.flyStops
          };
        }),
      };
      return fly;
    },

    remove() {
      this.deletePrestationGroup();
    },
    cancel() {
      this.cancelPrestationGroup();
    },
    bookFlight() {
      this.disablePanel = true;
      const url = `/api/prestation/book/flight?bookingId=${this.booking.id}&prestationId=${this.prestation.id}`;
      get(url)
          .badRequest((err) => {
            EventBus.$emit(
                "toaster-msg",
                JSON.parse(err.message).errors.join(",")
            );
          })
          .res(() => {
            EventBus.$emit("toaster-msg", "Vols reservé");
            this.disablePanel = false;
            EventBus.$emit("reloadbooking", {});
          });
    },

    detachPnr() {
      const url = `/api/pnr/detach?bookingId=${this.booking.id}&prestationId=${this.prestation.id}`;
      get(url)
          .badRequest((err) => {
            EventBus.$emit(
                "toaster-msg",
                JSON.parse(err.message).errors.join(",")
            );
          })
          .res(() => {
            EventBus.$emit("toaster-msg", "Pnr Détaché");
            EventBus.$emit("reloadbooking", {});
          });
    },

    cancelPnr() {
      const url = `/api/pnr/cancel?bookingId=${this.booking.id}&prestationId=${this.prestation.id}`;
      get(url)
          .badRequest((err) => {
            EventBus.$emit(
                "toaster-msg",
                JSON.parse(err.message).errors.join(",")
            );
          })
          .res(() => {
            EventBus.$emit("toaster-msg", "Pnr annulé");
            EventBus.$emit("reloadbooking", {});
          });
    },

    issueFlight() {
      const url = `/api/prestation/issue/flights?bookingId=${this.booking.id}&prestationId=${this.prestation.id}`;
      get(url)
          .badRequest((err) => {
            EventBus.$emit(
                "toaster-msg",
                JSON.parse(err.message).errors.join(",")
            );
          })
          .res(() => {
            EventBus.$emit("toaster-msg", "Pnr émis");
            EventBus.$emit("reloadbooking", {});
          });
    },

    //old = PHV69T
    // normal = PMDV7D
    getStopoverCount() {
      let count = 0;
      this.prestation.route.segments.map((s) => count++);
      this.stopovers = count - 1;
    },
    maj() {
      this.disablePanel = true;
      const url = `/api/prestation/majflights?bookingId=${this.booking.id}&prestationId=${this.prestation.id}&pnr=${this.prestation.pnr}`;
      get(url)
          .badRequest((err) => {
            var msg = "Impossible de mettre à jour le vol. ";
            try {
              msg += JSON.parse(err.message).errors.join(",");
            } catch (e) {
              msg += err;
            }

            EventBus.$emit("toaster-msg", msg);
          })
          .json((r) => {
            const msg = r.issame
                ? "Aucune différence appliquée"
                : "Le vol à été mis à jour. Veuillez verifier les informations";
            EventBus.$emit("toaster-msg", msg);
            if(r.changed.length>0)
            {
              EventBus.$emit("pnr-updated", r);
            }
          })
          .then(() => {
            this.disablePanel = false;
            EventBus.$emit("reloadbooking", {});
          });
    },
    getFlightPriceDetails() {
      const url = `/api/prestation/${this.prestation.id}/detail`;

      get(url).json((response) => {
        this.prestationWithDetails = {
          ...this.prestation,
          details: response,
        };
      });
    },

    getCompetitorsPrice() {
      get(`/api/prestation/${this.prestation.id}/competitors`).json((data) => {
        this.competitors = data
      });
    },

    createFlightTicket() {
      this.dialogCreateTicket.loading = true;
      const url = "/api/ticket/create";
      const obj = {
        ...{
          prestationFlightId: this.prestation.id,
        },
        ...this.dialogCreateTicket.value,
      };

      post(url, obj)
          .badRequest((err) => {
            this.dialogCreateTicket.loading = false;
            EventBus.$emit(
                "toaster-msg",
                JSON.parse(err.message).errors.join(", ")
            );
          })
          .res((response) => {
            EventBus.$emit("toaster-msg", "Ticket enregistré");
            EventBus.$emit("reloadbooking", {});
            this.dialogCreateTicket.loading = false;
            this.dialogCreateTicket.show = false;
          });
    },
    getOtherFlies() {
      get(`/api/prestation/${this.prestation.id}/other-flies`).json((data) => {
        this.otherFlies = data
      })
    },
    getStopoverOtherFlies(fly) {
      let count = 0;
      fly.segments.map((s) => count++);
      if (fly.segments.length == 1) {
        return `Direct`
      } else if (fly.segments.length > 1) {
        let stopopvers = fly.segments.filter((s, i) => {
          if (i > 0) {
            return s.from.code
          }
        })
        return `${count - 1} escale(s) (${stopopvers.map(s => s.from.code)})`;

      }
    },
    flightDuration(fly) {
      return this._renderDuration(fly.duration)
    },
    flightIndex(flies) {
      if (this.prestation.route.fromCode === flies[0].from.code) {
        return 0
      } else return 1
    },
    getTravelClasses(prestation) {
      const classes = prestation.route.segments.map(s => s.travelClass)
      return classes.join(", ")
    },

  },
};
</script>

<style scoped>
.icon-segment {
  transform: rotate(90deg);
}

.clouded {
  opacity: 0.25;
}
</style>
