<template>
  <v-card>

    <v-row dense>
      <v-col class="py-0">
        <v-expansion-panels accordion multiple flat tile>
          <v-expansion-panel v-for="(itenary, i) in flight.flies" :key="i" class="elevation-0"
            style="border-bottom:  solid 1px #EEEEEE; border-right:  solid 1px #EEEEEE;">
            <v-expansion-panel-header hide-actions class="elevation-0 pa-0">
              <v-container fluid class="px-1">
                <v-row dense class="align-center">
                  <v-col @click="(e) => pin(e, itenary, i)" class="text-center justify-center align-center"
                    style="max-width: 40px;">
                    <v-btn text icon small :color="isSelected(itenary, i) ? 'blue' : ''">
                      <v-icon small>push_pin</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="text-center" style="max-width: 120px;">

                    <v-avatar size="36"  style="border:solid 1px #BDBDBD;background-color: white;z-index: 10;">
                      <img class="logos-square"
                        :src="'/airlines/square/' + flight.prices[0].details[0].validatingAirline + '.png'"
                        style="width: 28px;height: 28px;">
                    </v-avatar>
                    <v-avatar size="36"  style="border:solid 1px #BDBDBD;background-color: white;" :style="{left: ((index+1) * -10) + 'px', zIndex: 9-index}" v-for="(airline,index) in new Set(itenary.segments.map(s => s.marketingAirline.code).filter(code => code != flight.prices[0].details[0].validatingAirline))">
                      <img
                        :key="airline" class="logos-square" :src="'/airlines/square/' + airline + '.png'" 
                        style="width: 28px;height: 28px;"/>
                    </v-avatar>

                  </v-col>
                  <v-col cols="1" class="text-right">
                    <div class="font-weight-bold body-1">{{ _parseAndFormatLocalDateTime(itenary.departureDate, "HH:mm") }}
                    </div>
                    <div class="grey--text lighten-2 mt-1 text-caption">{{
                      _parseAndFormatLocalDateTime(itenary.departureDate, "eee dd MMM") }}</div>
                  </v-col>
                  <v-col cols="4" class="text-center text-caption">
                    <v-row dense>
                      <v-col cols="3" class="text-right pr-3"><v-icon small color="grey lighten-2">flight_takeoff</v-icon></v-col>
                      <v-col>{{ _renderDuration(itenary.duration) }}</v-col>
                      <v-col cols="3" class="text-left pl-3"><v-icon small color="grey lighten-2">flight_land</v-icon></v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="3" class="text-right pr-2">{{ itenary.from.code }}</v-col>
                      <v-col style="border-top: 4px dotted #E0E0E0">
                        <template v-if="itenary.segments.length > 1">
                          <v-chip x-small outlined label class="px-1 mr-1"
                            v-for="(s, sIndex) in itenary.segments.slice(1)">{{ s.from.code
                            }} <span class="ml-1 grey--text text--darken-1" style="font-size: 9px;">{{
                              _durationFormatted(
                                _parseDate(itenary.segments[sIndex].arrivalDate, datePatternConfig.serverLocalDateTime),
                                _parseDate(s.departureDate, datePatternConfig.serverLocalDateTime),
                                datePatternConfig.fr.time
                              ) }}</span></v-chip>
                        </template>
                        <span class="blue--text text--lighten-2 font-weight-bold" v-else>Direct</span>
                      </v-col>
                      <v-col cols="3" class="text-left pl-2">{{ itenary.to.code }}</v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="1" class="text-left">
                    <div class="font-weight-bold body-1">{{ _parseAndFormatLocalDateTime(itenary.arrivalDate, "HH:mm") }}</div>
                    <div class="grey--text lighten-2 mt-1 text-caption">{{
                      _parseAndFormatLocalDateTime(itenary.arrivalDate, "eee dd MMM")
                    }}</div>
                  </v-col>
                  <v-col>
                    <v-chip x-small outlined label v-for="t in itenary.tags" :key="t" class="mr-1"
                      :class="[t.good ? 'green--text' : 'red--text']">{{ $tc('flight-tags.'+t.type) }}</v-chip>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="elevation-0 pt-2 grey lighten-4">
              <v-card>
                <v-card-text class="pa-0 mt-3">
                  <flight-segment :fly="itenary"></flight-segment>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="2" class="d-flex flex-column">

        <div class="d-flex" style="gap:5px">
          <v-chip x-small outlined label
            :class="[flight.prices[0].details[0].fareType.unifares ? 'green--text' : 'blue--text']">{{
              flight.prices[0].details[0].fareType.unifares ? 'NEGO' : 'PUB' }}</v-chip>
          <v-chip x-small outlined label color="">
            <v-icon x-small left color="grey">timer</v-icon>
            {{ _parseAndFormatLocalDateTime(flight.prices[0].details[0].lastTicketingDate, 'dd/MM HH:mm') }}
          </v-chip>
          <v-chip x-small outlined label>
            <v-icon x-small left color="grey">event_seat</v-icon>
            {{ flight.prices[0].details[0].numberOfBookableSeats }}
          </v-chip>
        </div>
        <div class="flex-grow-1 d-flex flex-column justify-center align-center text-h6">
          {{ flight.prices[0].pricing.price.toLocaleString() }} €
        </div>
        <div class="text-center"><v-btn small outlined color="blue lighten-1" @click="selectFlight">Sélectionner</v-btn>
        </div>
      </v-col>
    </v-row>

  </v-card>
</template>
<script>
import FlightSegment from "@/components/booking/flight/FlightSegment";
export default {
  name: "AddFlight",
  components: {
    FlightSegment
  },
  props: {
    'flight': {
      type: Object, required: true
    },
    'itenaries': { type: Array, default: () => ([]) }
  },
  methods: {
    pin(event, itenary, index) {
      event.stopPropagation();
      this.$emit('pin', { hash: itenary.hash, index: index })
      return false
    },
    selectFlight() {
      this.$emit('selectFlight', this.flight)
    },
    isSelected(itenary, itenaryIndex) {
      return this.itenaries.findIndex(i => i.index == itenaryIndex && i.hash == itenary.hash) !== -1
    }
  }
}
</script>
<style>
.v-expansion-panel-header {
  height: 60px !important;
}
</style>