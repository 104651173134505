<template>
  <layout :drawerShow="_isDesktop()" :drawer="true" :drawerWidth="350">
    <template slot="drawer">
      <v-form ref="form" v-model="valid" @submit.prevent="loadData()">
        <v-row dense>
          <v-col class="mt-2">
            <smart-select
              v-model="searchform.suppliers"
              label="Fournisseurs"
              :items="suppliers"
              item-text="name"
              item-value="id"
              :chip-style="{ width: '120px' }"
              :default-select-all="true"
              :all-is-same-as-empty="true"
              :loading="loadingSuppliers"
            >
            </smart-select>
          </v-col>

          <v-col cols="12" class="">
            <v-subheader light class="pa-0 mb-0 mx-0 mt-2 form-subheader"
              >Dates de réservation | Dossier
            </v-subheader>
            <v-divider></v-divider>
          </v-col>

          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.bookingStartDate"
              :inputOpts="{ label: $t('begin') }"
              label="Début de réservation"
              :next-picker="$refs.bookingStopDate"
              :stopDate="searchform.bookinStopDate"
            />
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="searchform.bookingStopDate"
              :inputOpts="{ label: $t('end') }"
              label="Fin de réservation"
              :startDate="searchform.bookingStartDate"
              :min="searchform.bookingStartDate"
              ref="stopDate"
            />
          </v-col>

          <v-col cols="12" class="mt-3">
            <v-subheader light class="pa-0 mb-0 mx-0 mt-2 form-subheader"
              >Dates de voyage | Prestation
            </v-subheader>
            <v-divider></v-divider>
          </v-col>

          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.prestationStartDate"
              :inputOpts="{ label: $t('begin') }"
              label="Début de prestation"
              :next-picker="$refs.prestationStopDate"
              :stopDate="searchform.prestationStopDate"
            />
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="searchform.prestationStopDate"
              :inputOpts="{ label: $t('end') }"
              label="Fin de prestation"
              :startDate="searchform.prestationStartDate"
              :min="searchform.prestationStartDate"
              ref="stopDate"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="mt-4" cols="12">
            <smart-btn
              block
              primary
              type="submit"
              :disabled="
                (searchform.startDate && !searchform.stopDate) ||
                (!searchform.startDate && searchform.stopDate)
              "
              :loading="loading"
              >Rechercher
            </smart-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template slot="content">
      <v-container fluid>
        <v-row justify="center">
          <widget-supplier-accounting-stats
            @loading="setLoading"
            :bus="widgetBus"
          ></widget-supplier-accounting-stats>
        </v-row>
      </v-container>
    </template>
  </layout>
</template>

<script>
import Layout from "../Layout";
import SmartMultiSelect from "../../components/commons/SmartMultiSelect";
import SmartSelect from "../../components/commons/SmartSelect";
import ColorMixin from "../../components/mixins/ColorMixin";
import FormMixin from "../../components/mixins/FormMixin";
import { get, post } from "../../utils/api";
import WidgetSupplierAccountingStats from "../../components/commons/widgets/WidgetSupplierAccountingStats";
import Vue from "vue";

export default {
  name: "SupplierAccountingStats",
  mixins: [ColorMixin, FormMixin],
  components: {
    Layout,
    SmartMultiSelect,
    SmartSelect,
    WidgetSupplierAccountingStats,
  },
  data() {
    return {
      widgetBus: new Vue(),
      valid: false,
      loading: false,
      loadingSuppliers: false,
      suppliers: [],
      searchform: {
        suppliers: [],
        destinations: [],
        bookingStartDate: null,
        bookingStopDate: null,
        prestationDateStart: null,
        prestationDateStop: null,
        sites:[]
      },
    };
  },
  created() {
    this.getSupplierAccounts();
    this.searchform.bookingStartDate = this._formatDate(
      this._now(),
      this.datePatternConfig.serverLocalDate
    );
    this.searchform.bookingStopDate = this._formatDate(
      this._now(),
      this.datePatternConfig.serverLocalDate
    );
  },
  methods: {
    getSupplierAccounts() {
      const url = `/api/supplier/get/foraccounting`;

      get(url)
        .json((r) => {
          let accounts = r.map((account) => {
            return { name: account.name, id: account.id };
          });

          this.suppliers = accounts;
        })
        .finally(() => this.loadData());
    },
    loadData() {
      if (!this.loading) {
        this.widgetBus.$emit("search", this.searchform);
      }
    },
    setLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>

<style scoped></style>
