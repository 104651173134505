<template>
  <div class="grey lighten-3">
    <v-toolbar>
      <v-row align="center">
        <v-col cols="4">
          <v-menu class="pt-2 pr-2 block" v-model="itenariesmenu" :close-on-content-click="false" min-width="1200"
            max-width="1200" bottom left offset-y>
            <template #activator="{ on }">
              <v-btn v-on="on" block outlined :color="onError ? 'red darken-1' : 'grey darken-4'"
                v-html="itenaryLabel"></v-btn>
            </template>

            <v-card dense class>
              <v-card-title class="pt-2">
                <v-btn-toggle v-model="flightsSizeButton" @change="sizeItenariesChange">
                  <v-btn small text :value="1">A</v-btn>
                  <v-btn small text :value="2">A/R</v-btn>
                  <v-btn small text :value="3">3 Vols</v-btn>
                  <v-btn small text :value="4">4 Vols</v-btn>
                </v-btn-toggle>
              </v-card-title>
              <v-card-text class="px-1 pt-2 pb-4">
                <flight-itenary v-for="(itenary, index) in itenaries" :key="index" :airports="airports"
                  :itenary="itenary" :index="index" :booking="booking" @itenarychange="itenarychange"></flight-itenary>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-col>
        <v-col cols="2" class>
          <booking-guest-repartition :guests="booking.globalGuests" v-model="guests"></booking-guest-repartition>
        </v-col>

        <v-col cols="1">
          <v-menu class :close-on-content-click="false" :nudge-width="150" bottom left offset-y>
            <template #activator="{ on }">
              <v-btn v-on="on" tertiary>{{ $t("options") }}</v-btn>
            </template>

            <v-card dense class="optmenu">
              <v-list dense>
                <v-list-item>
                  <v-list-item-action>
                    <v-switch dense v-model="publishedFares.select"></v-switch>
                  </v-list-item-action>
                  <v-list-item-title>{{ publishedFares.name }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-switch dense v-model="unifares.select"></v-switch>
                  </v-list-item-action>
                  <v-list-item-title>{{ unifares.name }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-switch dense v-model="corporateUnifares.select"></v-switch>
                  </v-list-item-action>
                  <v-list-item-title>{{ corporateUnifares.name }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-switch dense v-model="allowStops.select"></v-switch>
                  </v-list-item-action>
                  <v-list-item-title>{{ allowStops.name }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-switch dense v-model="allowAirportChange.select"></v-switch>
                  </v-list-item-action>
                  <v-list-item-title>{{ allowAirportChange.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
              <v-text-field label="Min Delay" v-model="mindelay"></v-text-field>
            </v-card>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2" class="text-right">
          <smart-btn primary @click.native="search" :loading="loading">
            <v-icon>search</v-icon>
            {{ $t("search") }}
          </smart-btn>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-container fluid>
      <v-row justify="center">
        <v-col cols="10">
          <v-row justify="space-around">
            <v-col class="text-center" cols="12" v-show="loading">
              <v-progress-circular v-show="loading" indeterminate color="primary" :width="3"
                :size="70"></v-progress-circular>
            </v-col>
            <v-col cols="12" class="pl-2 pr-2 text-center" v-show="!loading" v-if="flights.length == 0">
              <span v-if="hadSearchedOnce">{{ $t("no-results") }}</span>
              <span v-else>{{ $t("please-search") }}</span>
            </v-col>
            <v-col cols="12" class="pl-2 pr-2" v-show="!loading && flights.length > 0">
              <flight-price :flight-selected="flightSelected" :search-ctx="searchCtx"
                :search-passenger="searchPassenger" :booking="booking" :fly-trips="flights"></flight-price>
            </v-col>
          </v-row>
        </v-col>

        <!---------- prestation recap ---------->
        <v-col class="mt-1 pa-1" cols="2" v-if="sumPrestation"
          :style="'margin-top:' + prestationRecapMargin + ' !important;'">
          <v-card>
            <booking-drawer-content v-if="booking" :booking="booking"></booking-drawer-content>
          </v-card>
        </v-col>
        <!---------- end prestation recap ---------->
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { EventBus } from "@/components/commons/event-bus";
import { get, post } from "@/utils/api";
import RenderIconMixin from "@/components/mixins/RenderIconMixin";
import GuestsMixin from "@/components/mixins/GuestsMixin";
import BookingGuestRepartition from "@/components/commons/BookingGuestRepartition";
import FlightPrice from "@/components/booking/flight/FlightPrice";
import FlightItenary from "@/components/booking/flight/FlightItenary";
import BookingDrawerContent from "../../commons/BookingDrawerContent";

export default {
  name: "AddFlight",
  components: {
    FlightItenary,
    FlightPrice,
    BookingGuestRepartition,
    BookingDrawerContent,
  },
  mixins: [RenderIconMixin, GuestsMixin],
  props: {
    booking: {
      type: Object,
      required: true,
    },
    flightSelected: {
      type: Object,
      required: false,
    },
    sumPrestation: { required: true },
    lastPrestationDate: { required: true },
  },
  data: function () {
    return {
      itenaryLabel: "Selectionner vos vols",
      loading: false,
      hadSearchedOnce: false,
      optmenu: false,
      itenariesmenu: null,
      flightsSizeButton: 2,
      onError: false,
      itenaries: [],
      searchPassenger: [],

      airports: [],
      flights: [],

      searchCtx: {},

      guests: this.booking.globalGuests,
      from: null,
      to: null,
      mindelay: 2,

      publishedFares: { select: true, name: "Published Fares" },
      unifares: { select: true, name: "Unifares" },
      corporateUnifares: { select: false, name: "Corporate Unifares" },
      allowStops: { select: true, name: "Allow stops" },
      allowAirportChange: { select: false, name: "Allow airport changes" },
      prestationRecapMargin: 0,
    };
  },
  created() {
    this.getAirports().then(() => {
      this.initItenaries();
    });
  },
  watch: {},
  computed: {},
  methods: {
    setInitFlightDate() {
      if (
        typeof this.lastPrestationDate !== "undefined" &&
        this.lastPrestationDate !== null
      ) {
        return this.lastPrestationDate.substring(0, 10);
      } else if (this.lastPrestationDate === undefined) {
        let date;

        if (this.itenaries && this.itenaries.length == 0 && this.booking) {
          date = this.booking.stopDate.substring(0, 10);
        } else if (this.itenaries && this.itenaries.length > 0) {
          date = this.itenaries[this.itenaries.length - 1].date;
        }
        return date;
      }
    },
    initItenaries() {
      if (this.flightSelected) {
        this.itenaries = this.flightSelected.allRoutes.map((route, index) => {
          return {
            from: route.fromCode,
            to: route.toCode,
            date: this._parseAndFormatDate(
              route.start,
              this.datePatternConfig.serverLocalDateTime,
              "dd/MM/yyyy"
            ),
            dateCtx: "Departure",
            extraDaysAround: { nb: 0, ctx: "BOTH" },
            travelClass: "ECONOMY",
            index: index,
          };
        });

        this.setItenaryLabel();
        this.doSearch();
      } else {
        let arr = [];
        for (let i = 0; i < 2; i++) {
          arr.push(this.defaultItenary(i));
        }
        this.itenaries = arr;
      }
    },
    defaultItenary(index) {
      return {
        from: null,
        to: null,
        date: this.setInitFlightDate(),
        dateCtx: "Departure",
        extraDaysAround: { nb: 0, ctx: "BOTH" },
        travelClass: "ECONOMY",
        index: index,
      };
    },
    getOrEmpty(v, prepend) {
      return v != null ? (prepend ? prepend : "") + v : "";
    },
    sizeItenariesChange(val) {
      if (val - 1 < this.itenaries.length) this.itenaries = this.itenaries.slice(0, val);
      else this.itenaries.push(this.defaultItenary(this.itenaries.length));

      this.setItenaryLabel();
    },

    itenarychange(val) {
      if (val.eventType == "from") {
        this.fromChange(val);
      } else if (val.eventType == "to") {
        this.toChange(val);
      } else if (val.eventType == "date") {
        this.dateChange(val);
      }
      this.setItenaryLabel();
    },
    fromChange(val) {
      if (val.index == 0) {
        this.itenaries[this.itenaries.length - 1].to = val.from;
        //this.itenaries[val.index + 1].date = val.date
      }
    },
    toChange(val) {
      if (val.index < this.itenaries.length - 1) {
        this.itenaries[val.index + 1].from = val.to;
        //this.itenaries[this.itenaries.length - 1].date = this.itenaries[this.itenaries.length ].date
      }
    },
    dateChange(val) {
      if (val.index < this.itenaries.length - 1) {
        this.itenaries[val.index + 1].date = val.date;
      }
    },

    setItenaryLabel() {
      this.itenaryLabel = this.itenaries
        .map((i) => {
          return (
            this.getOrEmpty(i.from) +
            this.getOrEmpty(i.to, "-") +
            " <small>" +
            this.getOrEmpty(i.date) +
            "</small>"
          );
        })
        .join(", ");
    },

    getAirports() {
      return get(`/api/prestation/add/search-airports`)
        .badRequest((err) => {
          EventBus.$emit("toaster-msg", JSON.parse(err.null).errors.join(","));
        })
        .json((data) => {
          this.airports = data.map((airport) => {
            return {
              ...airport,
              ...{ lowerName: airport.name.toLowerCase() },
              ...{ lowerCode: airport.code.toLowerCase() },
            };
          });
        });
    },

    search() {
      let onError = this.itenaries.length != this.flightsSizeButton;
      this.itenaries
        .map((itenary) => {
          return itenary.date == null || itenary.from == null || itenary.to == null;
        })
        .reduce((acc, cur) => {
          onError = onError && cur;
        }, onError);

      this.onError = onError;

      if (!this.onError) {
        this.prestationRecapMargin = "67px";
        this.doSearch();
      }
    },

    doSearch() {
      this.hadSearchedOnce = true;
      this.loading = true;

      this.searchPassenger = this.guests
        .map((g) => {
          return {
            ...{
              ...g,
              firstname: g.firstName,
              lastname: g.lastName,
              birthdate: g.birthDate, //birthdate in lowercase because of property name on smartprice's side
            },
            ...{ fares: ["STANDARD", "INCLUSIVE_TOUR"] },
          };
        })
        .map((g) => {
          delete g.birthDate;
          return g;
        });

      this.searchCtx = {
        itenaries: this.itenaries,
        passengers: this.searchPassenger,
        pricing: {
          currency: this.booking.currency,
          fareType: {
            publishedFares: this.publishedFares.select,
            unifares: this.unifares.select,
            corporateUnifares: this.corporateUnifares.select,
          },
        },
        restrictions: {
          maxConnections: 2,
          allowStops: this.allowStops.select,
          allowAirportChange: this.allowAirportChange.select,
          withAirlines: [],
          withoutAirlines: [],
        },
        preferences: {
          minDelayBeforeTicketing: this.mindelay,
          includedCheckedBagsOnly: true
        },
        env: {
          mode: "PROD",
          debug: false,
          siteId: this.booking.context.site.id,
          priceClassTypeId: this.booking.context.priceClassType.id,
        },
        forceRefresh: false,
        withDurationFilter: false,
        withProviders: ["Amadeus"],
        withSmartCache: false,
      };

      post(`/api/prestation/add/search-flights`, this.searchCtx)
        .badRequest((err) => {
          EventBus.$emit("toaster-msg", JSON.parse(err.null).errors.join(","));
        })
        .json((data) => {
          this.flights = JSON.parse(data.data).flyTrips;
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.block {
  display: block;
}

.optmenu .v-input.v-text-field {
  width: 100px;
  margin-left: 20px;
}
</style>
