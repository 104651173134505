<template>
  <div>
    <v-toolbar>
      <v-row align="center">
        <v-col cols="4">
          <v-menu class="pt-2 pr-2 block" v-model="itenariesmenu" :close-on-content-click="false" min-width="1200"
            max-width="1200" bottom left offset-y>
            <template #activator="{ on }">
              <v-btn v-on="on" block outlined :color="onError ? 'red darken-1' : 'grey darken-4'"
                v-html="itenaryLabel"></v-btn>
            </template>

            <v-card dense class>
              <v-card-title class="pt-2">
                <v-btn-toggle v-model="flightsSizeButton" @change="sizeItenariesChange">
                  <v-btn small text :value="1">A</v-btn>
                  <v-btn small text :value="2">A/R</v-btn>
                  <v-btn small text :value="3">3 Vols</v-btn>
                  <v-btn small text :value="4">4 Vols</v-btn>
                </v-btn-toggle>
              </v-card-title>
              <v-card-text class="px-1 pt-2 pb-4">
                <flight-itenary v-for="(itenary, index) in itenaries" :key="index" :airports="airports"
                  :itenary="itenary" :index="index" :booking="booking" @itenarychange="itenarychange"></flight-itenary>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-col>
        <v-col cols="2" class>
          <booking-guest-repartition :guests="booking.globalGuests" v-model="guests"></booking-guest-repartition>
        </v-col>

        <v-col cols="1">
          <v-menu class :close-on-content-click="false" :nudge-width="150" bottom left offset-y>
            <template #activator="{ on }">
              <v-btn v-on="on" tertiary>{{ $t("options") }}</v-btn>
            </template>

            <v-card dense class="optmenu">
              <v-list dense>
                <v-list-item>
                  <v-list-item-action>
                    <v-switch dense v-model="publishedFares.select"></v-switch>
                  </v-list-item-action>
                  <v-list-item-title>{{ publishedFares.name }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-switch dense v-model="unifares.select"></v-switch>
                  </v-list-item-action>
                  <v-list-item-title>{{ unifares.name }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-switch dense v-model="corporateUnifares.select"></v-switch>
                  </v-list-item-action>
                  <v-list-item-title>{{ corporateUnifares.name }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-switch dense v-model="allowStops.select"></v-switch>
                  </v-list-item-action>
                  <v-list-item-title>{{ allowStops.name }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-switch dense v-model="allowAirportChange.select"></v-switch>
                  </v-list-item-action>
                  <v-list-item-title>{{ allowAirportChange.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
              <v-text-field label="Min Delay" v-model="mindelay"></v-text-field>
            </v-card>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2" class="text-right">
          <smart-btn primary @click.native="search" :loading="loading">
            <v-icon>search</v-icon>
            {{ $t("search") }}
          </smart-btn>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-container fluid class="mt-5">
      <v-row>
        <v-col cols="2">
          <v-expansion-panels accordion multiple v-model="panel" v-if="flights.length > 0">
            <v-expansion-panel>
              <v-expansion-panel-header class="text-body-2">Airline</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox v-for="(airline) in airlines" hide-details multiple v-model="filters.airlines"
                  :label="airline" :value="airline" class="mt-0"></v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="text-body-2">Escales</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox v-for="(stop) in stops" hide-details multiple v-model="filters.stops"
                  :label="stop === 0 ? 'Direct' : $tc('flystops', stop, { size: stop })" :value="stop"
                  class="mt-0"></v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="text-body-2">Prix</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="d-flex">
                  <span>{{ filters.price[0] }}€</span>
                  <span class="ml-auto">{{ filters.price[1] }}€</span>
                </div>
                <v-range-slider dense hide-details v-model="filters.price" :min="prices.min"
                  :max="prices.max"></v-range-slider>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-for="(itenary, itenaryIndex) in itenaries">
              <v-expansion-panel-header class="text-body-2 align-center">
                Horaires
                <span class="ml-5 grey--text text--darken-2">{{ itenary.from }} <v-icon x-small class="mx-1" style="transform: rotate(90deg)">flight</v-icon> {{ itenary.to }}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="d-flex align-center">
                  <span style="width: 80px;">Départ</span>
                  <v-range-slider hide-details dense class="align-center"
                    v-model="filters.times[itenaryIndex].departure" :min="0" :max="23">
                    <template v-slot:prepend><span class="text-caption">{{ filters.times[itenaryIndex].departure[0]
                        }}</span></template>
                    <template v-slot:append><span class="text-caption">{{ filters.times[itenaryIndex].departure[1]
                        }}</span></template>
                  </v-range-slider>
                </div>
                <div class="d-flex align-center">
                  <span style="width: 80px;">Arrivée</span>
                  <v-range-slider hide-details dense class="align-center" v-model="filters.times[itenaryIndex].arrival"
                    :min="0" :max="23">
                    <template v-slot:prepend><span class="text-caption">{{ filters.times[itenaryIndex].arrival[0]
                        }}</span></template>
                    <template v-slot:append><span class="text-caption">{{ filters.times[itenaryIndex].arrival[1]
                        }}</span></template>
                  </v-range-slider>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col>
          <FlightV2 v-for="flight in filteredFlights" :flight="flight" :itenaries="filters.itenaries" class="mb-5"
            @pin="pinItenary" @selectFlight="selectFlight"
          />
        </v-col>
        <v-col cols="2">
          <v-card>
            <v-card-text>
              <booking-drawer-content v-if="booking" :booking="booking"></booking-drawer-content>
            </v-card-text>
          </v-card>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { EventBus } from "@/components/commons/event-bus";
import { get, post } from "@/utils/api";
import RenderIconMixin from "@/components/mixins/RenderIconMixin";
import GuestsMixin from "@/components/mixins/GuestsMixin";
import BookingGuestRepartition from "@/components/commons/BookingGuestRepartition";
import BookingDrawerContent from "../../commons/BookingDrawerContent";
import FlightItenary from "@/components/booking/flight/FlightItenary";
import FlightV2 from "@/components/booking/flight/FlightV2";

export default {
  name: "AddFlight",
  components: {
    FlightItenary,
    BookingGuestRepartition,
    BookingDrawerContent,
    FlightV2
  },
  mixins: [RenderIconMixin, GuestsMixin],
  props: {
    booking: {
      type: Object,
      required: true,
    },
    flightSelected: {
      type: Object,
      required: false,
      default: null
    },
    sumPrestation: { required: true },
    lastPrestationDate: { required: true },
  },
  data: function () {
    return {
      itenaryLabel: "Selectionner vos vols",
      loading: false,
      hadSearchedOnce: false,
      optmenu: false,
      itenariesmenu: null,
      flightsSizeButton: 2,
      onError: false,
      itenaries: [],
      searchPassenger: [],

      airports: [],
      flights: [],
      filters: {
        itenaries: [],
        airlines: [],
        stops: [],
        price: [],
        times: [
          { departure: [0, 24], arrival: [0, 24] },
          { departure: [0, 24], arrival: [0, 24] }
        ],
      },
      panel: [0, 1, 2, 3, 4, 5],

      searchCtx: {},

      guests: this.booking.globalGuests,
      from: null,
      to: null,
      mindelay: 2,

      publishedFares: { select: true, name: "Published Fares" },
      unifares: { select: true, name: "Unifares" },
      corporateUnifares: { select: false, name: "Corporate Unifares" },
      allowStops: { select: true, name: "Allow stops" },
      allowAirportChange: { select: false, name: "Allow airport changes" },
      prestationRecapMargin: 0,
    };
  },
  created() {
    this.getAirports().then(() => {
      this.initItenaries();
    });
  },
  computed: {
    filteredFlights() {
      return [
        this.filterItenary,
        this.filterValidatingAirline,
        this.filterPrice,
        this.filterTimes,
        this.filterStops
      ].reduce((flights, filter) => {
        return filter(flights)
      }, this.flights)
    },
    airlines() {
      return new Set(this.flights.map(f => f.prices[0].details[0].validatingAirline))
    },
    stops() {
      return [...new Set(this.flights.flatMap(f => f.flies.map(fly => fly.segments.length - 1)))].sort()
    },
    prices() {
      return this.flights
        .reduce((range, flight) => {
          const price = flight.prices[0].pricing.price
          return {
            min: range.min == null ? price : Math.min(range.min, price),
            max: range.max == null ? price : Math.max(range.max, price)
          }
        }, { min: null, max: null })

    }
  },
  watch: {
    prices: {
      immediate: true, handler(newVal) {
        this.filters.price = [this.prices.min, this.prices.max]
      }
    }
  },
  methods: {
    filterItenary(flights) {
      if (this.filters.itenaries.length == 0) {
        return flights
      }
      return flights.filter(f => {
        return this.filters.itenaries.every(i => f.flies[i.index].hash == i.hash)
      })
    },
    filterValidatingAirline(flights) {
      if (this.filters.airlines.length == 0) {
        return flights;
      }
      return flights.filter(f => {
        return this.filters.airlines.findIndex(a => a == f.prices[0].details[0].validatingAirline) !== -1
      });
    },
    filterPrice(flights) {
      return flights.filter(f => {
        return f.prices[0].pricing.price >= this.filters.price[0] && f.prices[0].pricing.price <= this.filters.price[1]
      });
    },
    filterTimes(flights) {

      return flights.filter(f => {
        return f.flies.every((fly, index) => {
          const departureHour = parseInt(this._parseAndFormatDate(fly.departureDate, this.datePatternConfig.serverLocalDateTime, "HH"))
          const arrivalHour = parseInt(this._parseAndFormatDate(fly.arrivalDate, this.datePatternConfig.serverLocalDateTime, "HH"))
          return departureHour >= this.filters.times[index].departure[0] && departureHour <= this.filters.times[index].departure[1] &&
            arrivalHour >= this.filters.times[index].arrival[0] && arrivalHour <= this.filters.times[index].arrival[1]
        })
      });

    },
    filterStops(flights) {
      if (this.filters.stops.length == 0) {
        return flights;
      }
      return flights.filter(f => {
        return f.flies.every(fly => this.filters.stops.findIndex(s => s == fly.segments.length - 1) !== -1)
      });
    },
    pinItenary(itenary) {
      const index = this.filters.itenaries.findIndex((i) => i.hash === itenary.hash && i.index === itenary.index)

      if (index === -1) {
        this.filters.itenaries.push(itenary);
      } else {
        this.filters.itenaries = this.filters.itenaries.filter(i => i.index != itenary.index && i.hash != itenary.hash)
      }
    },

    setInitFlightDate() {

      if (typeof this.lastPrestationDate !== "undefined" &&
        this.lastPrestationDate !== null) {
        return this.lastPrestationDate.substring(0, 10);
      } else if (this.lastPrestationDate === undefined) {
        let date;

        if (this.itenaries && this.itenaries.length == 0 && this.booking) {
          date = this.booking.stopDate.substring(0, 10);
        } else if (this.itenaries && this.itenaries.length > 0) {
          date = this.itenaries[this.itenaries.length - 1].date;
        }
        return date;
      }
    },
    initItenaries() {
      if (this.flightSelected) {
        this.itenaries = this.flightSelected.allRoutes.map((route, index) => {
          return {
            from: route.fromCode,
            to: route.toCode,
            date: this._parseAndFormatDate(
              route.start,
              this.datePatternConfig.serverLocalDateTime,
              "dd/MM/yyyy"
            ),
            dateCtx: "Departure",
            extraDaysAround: { nb: 0, ctx: "BOTH" },
            travelClass: "ECONOMY",
            index: index,
          };
        });

        this.setItenaryLabel();
        this.doSearch();
      } else {
        let arr = [];
        for (let i = 0; i < 2; i++) {
          arr.push(this.defaultItenary(i));
        }
        this.itenaries = arr;
      }
    },

    defaultItenary(index) {
      return {
        from: null,
        to: null,
        date: this.setInitFlightDate(),
        dateCtx: "Departure",
        extraDaysAround: { nb: 0, ctx: "BOTH" },
        travelClass: "ECONOMY",
        index: index,
      };
    },
    getOrEmpty(v, prepend) {
      return v != null ? (prepend ? prepend : "") + v : "";
    },

    sizeItenariesChange(val) {
      if (val - 1 < this.itenaries.length) this.itenaries = this.itenaries.slice(0, val);
      else this.itenaries.push(this.defaultItenary(this.itenaries.length));

      this.setItenaryLabel();
    },

    itenarychange(val) {
      if (val.eventType == "from") {
        this.fromChange(val);
      } else if (val.eventType == "to") {
        this.toChange(val);
      } else if (val.eventType == "date") {
        this.dateChange(val);
      }
      this.setItenaryLabel();

    },
    fromChange(val) {
      if (val.index == 0) {
        this.itenaries[this.itenaries.length - 1].to = val.from;
        //this.itenaries[val.index + 1].date = val.date
      }
    },
    toChange(val) {
      if (val.index < this.itenaries.length - 1) {
        this.itenaries[val.index + 1].from = val.to;
        //this.itenaries[this.itenaries.length - 1].date = this.itenaries[this.itenaries.length ].date
      }
    },
    dateChange(val) {
      if (val.index < this.itenaries.length - 1) {
        this.itenaries[val.index + 1].date = val.date;
      }
    },

    setItenaryLabel() {
      this.itenaryLabel = this.itenaries
        .map((i) => {
          return (
            this.getOrEmpty(i.from) +
            this.getOrEmpty(i.to, "-") +
            " <small>" +
            this.getOrEmpty(i.date) +
            "</small>"
          );
        })
        .join(", ");
    },

    getAirports() {
      return get(`/api/prestation/add/search-airports`)
        .badRequest((err) => {
          EventBus.$emit("toaster-msg", JSON.parse(err.null).errors.join(","));
        })
        .json((data) => {
          this.airports = data.map((airport) => {
            return {
              ...airport,
              ...{ lowerName: airport.name.toLowerCase() },
              ...{ lowerCode: airport.code.toLowerCase() },
            };
          });
        });
    },

    search() {
      let onError = this.itenaries.length != this.flightsSizeButton;
      this.itenaries
        .map((itenary) => {
          return itenary.date == null || itenary.from == null || itenary.to == null;
        })
        .reduce((acc, cur) => {
          onError = onError && cur;
        }, onError);

      this.onError = onError;

      if (!this.onError) {
        this.prestationRecapMargin = "67px";
        this.doSearch();
      }
    },

    doSearch() {
      this.hadSearchedOnce = true;
      this.loading = true;

      this.searchPassenger = this.guests
        .map((g) => {
          return {
            ...{
              ...g,
              firstname: g.firstName,
              lastname: g.lastName,
              birthdate: g.birthDate, //birthdate in lowercase because of property name on smartprice's side
            },
            ...{ fares: ["STANDARD", "INCLUSIVE_TOUR"] },
          };
        })
        .map((g) => {
          delete g.birthDate;
          return g;
        });

      this.searchCtx = {
        itenaries: this.itenaries,
        passengers: this.searchPassenger,
        pricing: {
          currency: this.booking.currency,
          fareType: {
            publishedFares: this.publishedFares.select,
            unifares: this.unifares.select,
            corporateUnifares: this.corporateUnifares.select,
          },
        },
        restrictions: {
          maxConnections: 2,
          allowStops: this.allowStops.select,
          allowAirportChange: this.allowAirportChange.select,
          withAirlines: [],
          withoutAirlines: [],
        },
        preferences: {
          minDelayBeforeTicketing: this.mindelay,
          includedCheckedBagsOnly: true
        },
        env: {
          mode: "PROD",
          debug: false,
          siteId: this.booking.context.site.id,
          priceClassTypeId: this.booking.context.priceClassType.id,
        },
        forceRefresh: false,
        withDurationFilter: false,
        withProviders: ["Amadeus"],
        withSmartCache: false,
      };

      this.flights = []
      this.filters.times = this.itenaries.map(i => ({ departure: [0, 23], arrival: [0, 23] }))
      post(`/api/prestation/add/search-flights`, this.searchCtx)
        .badRequest((err) => {
          EventBus.$emit("toaster-msg", JSON.parse(err.null).errors.join(","));
        })
        .json((data) => {
          this.flights = JSON.parse(data.data).flyTrips;
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    selectFlight(flight) {  
      if (this.flightSelected){
        this.edit(flight)
      } else {
        this.add(flight)
      }
    },

    add(flight) {
      this.loading = true;

      post(`/api/prestation/add/flights?bookingId=${this.booking.id}`, this.buildCtx(flight))
        .internalError((err) => {
          EventBus.$emit("toaster-error", this.$t("error-500"));
        })
        .badRequest((err) => {
          EventBus.$emit("toaster-error", JSON.parse(err.message).errors.join(","));
        })
        .json((data) => {
          EventBus.$emit("toaster-msg", this.$t("flightadded"));
          setTimeout(() => {
            EventBus.$emit("reloadbooking", {});
            EventBus.$emit("prestation-added", {});
          }, 500);
        })
        .then(() => {
          this.loading = false;
          EventBus.$emit("flightadded", {});
        })
        .catch(() => {
          this.loading = false;
        });
    },

    edit(flight) {
      this.loading = true;

      post(
        `/api/prestation/edit/flights?bookingId=${this.booking.id}&prestationGroupId=${this.flightSelected.prestationGroupId}`,
        this.buildCtx(flight)
      )
        .forbidden((err) => console.log(err.status)) //TODO
        .internalError((err) => {
          EventBus.$emit("toaster-error", this.$t("error-500"));
        })
        .badRequest((err) => {
          EventBus.$emit("toaster-error", JSON.parse(err.message).errors.join(","));
        })
        .json((data) => {
          EventBus.$emit("toaster-msg", this.$t("flightadded"));
          EventBus.$emit("reloadbooking", {});
          this.editdialog = false;
          setTimeout(() => {
            EventBus.$emit("flight-edited", {});
          }, 500);
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    buildCtx(flight) {
      
      return {
        ctx: {
          flies: flight.flies,
          price: flight.prices[0],
          passengers: flight.passengers,
          customer: {
            countryCode: this.booking.customer.address.country,
            email: this.booking.customer.email,
            tel: this.booking.customer.phone != "" ? this.booking.customer.phone : null,
          },
          bookingId: this.booking.id,
          env: {
            mode: "PROD",
            siteId: this.booking.context.site.id,
            priceClassTypeId: this.booking.context.priceClassType.id,
          },
        },
        guests: this.searchPassenger,
      };
    },
  },
};
</script>

<style>
.block {
  display: block;
}

.optmenu .v-input.v-text-field {
  width: 100px;
  margin-left: 20px;
}
</style>
