<template>
  <v-card ref="vCardRef"
          :elevation="1"
          class="pa-4"
          style="height:100%; width:100%; overflow: hidden">
    <div class="d-flex flex-row justify-space-between mb-8">
      <span class="mr-10 font-weight-bold">
        {{ conf.title }}</span>
      <div>
        <span @click="viewBy('day')"
              :class="actualView == 'day'
                ? 'font-weight-black pointer-event-none'
                : 'text-decoration-underline cursor-pointer'">Jour</span> |
        <span @click="viewBy('week')"
              :class="actualView == 'week'
                ? 'font-weight-black pointer-event-none'
                : 'text-decoration-underline cursor-pointer'">Semaine</span> |
        <span @click="viewBy('month')"
              :class="actualView == 'month'
              ? 'font-weight-black pointer-event-none'
              : 'text-decoration-underline cursor-pointer'">Mois</span>
      </div>
    </div>
    <apexchart
        ref="apexcharts"
        type="area"
        :height="series.length > 0 ? chartHeight : null"
        :options="options"
        :series="series"
        v-if="series.length > 0"
    >

  </apexchart>
  </v-card>
</template>
<script>
import {defineComponent, ref} from "vue";
import {addYears, getDayOfYear, getDaysInYear, isValid} from "date-fns";
import {post} from "@/utils/api";
import _ from "lodash";
import {EventBus} from "@/components/commons/event-bus";
//Chiffre d'affaires
export default defineComponent({
  name: "SimpleDateCAChart",
  components: {},
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}
  },
  data() {
    return {
      vCardRef:ref(null),
      rawDataTable: null,
      url: `/api/widget/dashboard/chart`,
      actualView: 'week',
      importantsDate: [],
      series:[],
      options: {
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          width: 3,
          dashArray: 0,
        },
        chart: {
          defaultLocale: 'fr',
          id: "area",
          datetimeUTC: false,
          type: "area",
          stacked: false,
          height: 350,
          zoom: {
            enabled: false,
            autoScaleYaxis: true,
          },
          locales: [{
            name: "fr",
            options: {
              months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"],
              shortMonths: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
              days: ["Dimanche", "Lundi", "Merdi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
              shortDays: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
            }
          }],
        },
        yaxis: {
          type: 'numeric',
          decimalsInFloat: false,
        },
        xaxis: {
          floating: false,
          position: 'bottom',
          axisTicks: {
            show: true,
          },
          min: new Date(`${new Date().getFullYear()}-01-01`),
          max: new Date(`${new Date().getFullYear()}-12-31`),
          type: 'datetime',
          tickAmount: 'dataPoints',
          labels: {
            datetimeUTC: false,
            show: true,
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMMM ',
              day: 'dd MMMM',
              hour: 'HH:mm'
            },
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: true,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          custom: {},
          x: {
            show: false,
            format: '',
            formatter: undefined,
          },
          fixed: {
            enabled: false,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },

        },
        fill: {
          type: 'gradient',
        },
        legend: {position: 'top'},
        annotations: {
          xaxis: [],
        },
      },
    }
  },
  created() {
    EventBus.$on('editMode', () => this.updateSize())
    EventBus.$on('searchDashboard', () => this.init());
    this.init()
  },
  mounted(){
      this.vCardRef = this.$refs.vCardRef.$el.clientHeight
      if(this.conf.chartOptions == null){
          this.conf.chartOptions = this.options
        delete this.conf.chartOptions.xaxis.min
        delete this.conf.chartOptions.xaxis.max
      } else {
        this.options = {...this.options, ...this.conf.chartOptions}
      }
  },
  computed: {
    chartHeight() {
      return this.vCardRef != null ? `${this.$refs.vCardRef.$el.clientHeight-100}px` : 'auto';
    },
  },
  methods: {
    init() {
      const searchForm = this._formatPayloadWidgetDashboard(this.conf,this.form)
      this.series = []
      let data = post(this.url, searchForm, {"Content-Type": "application/json"})
          .json(rs => {
            this.rawDataTable = rs

            this.options.xaxis.min = this.rawDataTable.yearPast.length > 0 ? this.rawDataTable.yearPast[0].date.split("-")[0] : new Date(`${new Date().getFullYear()}-01-01`).toString()
            this.options.xaxis.max = new Date(`${new Date().getFullYear()}-12-31`).toString()
            if (this.conf.view != null) {
              switch (this.conf.view) {
                case 'month':
                  this.viewBy('month')
                  this.actualView = "month"
                  break;
                case "day":
                  this.viewBy('day')
                  this.actualView = "day"
                  break;
                case "week" :
                  this.viewBy('week')
                  this.actualView = "week"
                  break;
              }
            } else {
              this.viewBy('week')
            }
            const self = this
            this.options.tooltip.custom = function ({series, seriesIndex, dataPointIndex, w}) {
              const valueN = self._formatCurrency(series[0][dataPointIndex], 'EUR')
              const valueO = self._formatCurrency(series[1][dataPointIndex], 'EUR')
              const date = seriesIndex == 1
                  ? new Date(w.globals.seriesX[seriesIndex][dataPointIndex]).setFullYear(new Date(self.form.startDateAct).getFullYear())
                  : new Date(w.globals.seriesX[seriesIndex][dataPointIndex])

              const color = ["rgb(200, 200, 200)", "rgb(90, 158, 245)"]
              if (date !== null && isValid(date) && w.config.series[seriesIndex] != null && series[seriesIndex] != null) {
                let firstLine = ""
                let secondLine = ""
                if (valueN != null && valueN != "") {
                  firstLine = `<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;" >
                  <span class="apexcharts-tooltip-marker" style="background-color: ${color[0]}"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                      <div class="apexcharts-tooltip-y-group d-flex flex-row justify-space-between">
                        <span class="apexcharts-tooltip-text-y-label">${w.config.series[0].name} </span>
                        <span class="apexcharts-tooltip-text-y-value">${valueN}</span>
                      </div>
                    </div>
                  </div>`
                }

                if (valueO != null && valueO != "") {
                  secondLine = `  <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                  <span class="apexcharts-tooltip-marker" style="background-color: ${color[1]}"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                      <div class="apexcharts-tooltip-y-group d-flex flex-row justify-space-between">
                        <span class="apexcharts-tooltip-text-y-label">${w.config.series[1].name} </span>
                        <span class="apexcharts-tooltip-text-y-value">${valueO}</span>
                      </div>
                    </div>`
                }
                return `
                <div style="left: 663.205px; top: 107.287px;">
                  <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                  ${self._formatDate(date, "eee dd MMMM yyyy")}
                  </div>
                  ${firstLine}
                  ${secondLine}
                  </div>
                </div>`
              } else {
                return ""
              }
            }
            let annotationLabel = null
            this.importantsDate= this._getHotelissimaImportantDates()
            this.importantsDate.forEach((data => {
              const dateShowed = new Date(data.date)
              dateShowed.setFullYear(new Date().getFullYear()-1)
              this.options.annotations.xaxis.push({
                x: dateShowed.getTime(),
                borderColor: '#999',
                yAxisIndex: 0,
                label: {
                  show: true,
                  textAnchor: "middle",
                  
                  orientation: "horizontal",
                  text: "*",
                  mouseEnter: (o,n) => {

                    const rect = self.$refs.apexcharts.$el.getBoundingClientRect()
                    const y = n.y - rect.y + 25
                    const x = n.x - rect.x + 25
                    
                    annotationLabel = document.createElement("div")

                    annotationLabel.style.position = 'absolute';
                    annotationLabel.style.background = '#775DD0';
                    annotationLabel.style.padding = "5px"
                    annotationLabel.style.color = "#fff"
                    annotationLabel.style.top = y+"px"
                    annotationLabel.style.left = x+"px"
                    annotationLabel.style.transform = "translateX(-50%)"
                    annotationLabel.appendChild(document.createTextNode(this._formatDate(new Date(data.date), "dd/MM/yyyy") + " : " + data.name))

                    self.$refs.apexcharts.$el.appendChild(annotationLabel)
                    
                  },
                  mouseLeave: () => {
                    self.$refs.apexcharts.$el.removeChild(annotationLabel)
                  },
                  style: {
                    color: "#fff",
                    background: '#775DD0'
                  }
                },
              })

            }))
          })
      
    },
    updateSize(){
      this.vCardRef = this.$refs.vCardRef != null ? this.$refs.vCardRef.$el.clientHeight : 0
    },
    chartByMonth() {
      const rawData = JSON.parse(JSON.stringify(this.rawDataTable))
      const refYear = rawData.yearPast.length > 0 ? rawData.yearPast[0].date.split("-")[0] : new Date().getFullYear()
      const yearN = this.groupByMonth(rawData.yearN, refYear)
      const yearPast = this.groupByMonth(rawData.yearPast, refYear)
      this.series.push({
        name: "Période N-1 (€)",
        data: yearPast,
        color: 'rgb(200,200,200)'
      }, {name: "Période actuelle (€)", data: yearN, color: "rgb(90, 158, 245)"})
    },
    chartByDay() {
      const r = JSON.parse(JSON.stringify(this.rawDataTable))
      const refYear = r.yearPast.length > 0 ? r.yearPast[0].date.split("-")[0] : new Date().getFullYear()
      const pastYear = []
      r.yearPast.forEach(date => pastYear.push([new Date(date.date).getTime(), parseFloat(date.value)]))
      const actualYear = [];
      r.yearN.forEach(date => actualYear.push([new Date(date.date).setFullYear(refYear), parseFloat(date.value)]))
      this.series.push({
        name: "Période N-1 (€)",
        data: pastYear,
        color: 'rgb(200,200,200)'
      }, {name: "Période actuelle (€)", data: actualYear, color: "rgb(90, 158, 245)"})
    },
    chartByWeek() {
      const r = JSON.parse(JSON.stringify(this.rawDataTable))
      const refYear = r.yearPast.length > 0 ? r.yearPast[0].date.split("-")[0] : new Date().getFullYear()
      const yearN = this.groupByWeek(r.yearN, refYear)
      const yearPast = this.groupByWeek(r.yearPast, refYear)
      this.series.push({
        name: "Période N-1 (€)",
        data: yearPast,
        color: 'rgb(200,200,200)'
      }, {name: "Période actuelle (€)", data: yearN, color: "rgb(90, 158, 245)"})
    },
    groupByMonth(list, refYear) {
      const yearData = _.groupBy(list, e => {
        return this._parseAndFormatDate(e.date, "yyyy-MM-dd", 'MM')
      })
      return Object.keys(yearData).map((key) => {
        const date = this._parseDate(yearData[key][0].date, "yyyy-MM-dd")
        date.setFullYear(refYear)
        return [date, _.sumBy(yearData[key], (v) => parseFloat(v.value))]
      }).sort((a, b) => {
        return a[0] - b[0]
      })
    },
    groupByWeek(list, refYear) {
      const yearData = _.groupBy(list, e => {

        const d = this._parseDate(e.date, "yyyy-MM-dd")

        return Math.ceil(
            (getDayOfYear(d) * 52) / getDaysInYear(d)
        )

      })
      let result =  Object.keys(yearData).map((key) => {
        const date = this._parseDate(yearData[key][0].date, "yyyy-MM-dd")
        date.setFullYear(refYear)
        return [date, _.sumBy(yearData[key], (v) => parseFloat(v.value))]
      }).sort((a, b) => {
        return a[0] - b[0]
      })
      return result
    },
    viewBy(timeUnit) {
      this.options.tooltip.x.format = null
      this.series = []
      this.actualView = timeUnit;
      switch (timeUnit) {
        case 'day': {
          this.options.tooltip.x.format = 'dd MMMM'
          this.chartByDay()
          break;
        }
        case 'month': {
          this.options.tooltip.x.format = 'MMMM'
          this.chartByMonth()
          break;
        }
        case 'week': {
          this.options.tooltip.x.format = 'dd MMMM'
          this.chartByWeek()
          break;
        }

      }
    },
  }
})
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.pointer-event-none {
  pointer-events: none;
}
</style>