<template>
  <WidgetDatatableTabs :items="data.data"
                       :headersList="headers"
                       defaultTarget="count"
                       :nbTabs="0"
                       :comparable="false"
                       reverseChips
                       :searchable="false"
                       >
    <template #title>{{ conf.title }}</template>
  </WidgetDatatableTabs>
</template>
<script>
import {post} from "@/utils/api";
import WidgetTile from "@/components/commons/widgets/WidgetTile.vue";
import {EventBus} from "@/components/commons/event-bus";
import WidgetDatatableTabs from "@/components/commons/widgets/WidgetDatatableTabs.vue";
//Ratio de leadconvertis par origine
export default {
  name: "ConvertedLeadBySourceTable",
  components: {WidgetDatatableTabs},
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}
  },
  data() {
    return {
      url: `/api/widget/dashboard/lead/ratio/source`,
      headers:[
        {
          text: "Nom",
          value: "name",
          align: "left",
          sortable: true,
        },
        {
          text: "% lead convertis",
          value: "count",
          align: "left",
          sortable: true,
        },
      ],
      data: {
        isPrice:false,
        data: [],
      },
      loading: true,
    }
  },
  computed: {},
  created() {
    EventBus.$on('searchDashboard', () => this.init());
    this.init();
  },
  methods: {
    init() {
      const searchForm = this._formatPayloadWidgetDashboard(this.conf, this.form)
      let tile = post(this.url, searchForm, {"Content-Type": "application/json"})
          .json((r) => {

            this.data.data = r.map(src => {
              return {
                name: src.name,
                count: src.value1,
                total: src.total,
                isPrice: false
              }
            })
          })
      Promise.all([tile]).then(() => {
        this.loading = false
      })
    },
  }
}
</script>