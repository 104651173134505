<template>
  <WidgetDatatableTabs :items="data.data" defaultTarget="sales"
                       :nbHeaders="4"
                       :headersList="headers"
                       :nbTabs="4"
                       showTotal>
    <template #title>{{ conf.title }}</template>
  </WidgetDatatableTabs>
</template>
<script>
import {post} from "@/utils/api";
import WidgetTile from "@/components/commons/widgets/WidgetTile.vue";
import {EventBus} from "@/components/commons/event-bus";
import WidgetDatatableTabs from "@/components/commons/widgets/WidgetDatatableTabs.vue";
// H&L - vente par réseau
export default {
  name: "SalesByNetworkTable",
  components: {WidgetDatatableTabs},
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}
  },
  data() {
    return {
      url: `/api/widget/hl/dashboard/network-sales`,
      data: {
        data: [],
      },
      loading: true,
      headers:[
        {text:"count",value:'count', isPrice:false},
        {text:"sales",value:'sales'},
        {text:"purchases",value:'purchases'},
        {text:"profits",value:'profits'},
      ]
    }
  },
  computed: {},
  created() {
    EventBus.$on('searchDashboard', () => this.init());
    this.init();
  },
  methods: {
    init() {
      const searchForm = this._formatPayloadWidgetDashboard(this.conf, this.form)
      post(this.url, searchForm, {
        "Content-Type": "application/json",
      }).json((r) => {
        this.data.data = this.formatDataTable(r.yearN, r.yearPast)
      })

    },
    formatDataTable(actual, old) {
      let toPushinActual = []
      old.forEach(data => {
        if (!actual.find(n => data.name === n.name)) {
          toPushinActual.push(
              {
                name: data.name,
                count: 0,
                sales: 0,
                purchases: 0,
                isPrice: data.isPrice != null ? data.isPrice : false,
                last: data,
                profits: 0
              }
          )

        }
      })

      actual.forEach(data => {
        if (!old.find(n => data.name === n.name)) {
          data.last = {
            name: data.name,
            count: 0,
            sales: 0,
            purchases: 0,
            isPrice: data.isPrice,
            profits: 0
          }
        }
      })
      actual.push(...toPushinActual)
      actual.forEach((sale) => {
        sale.last = old.find(oldSale => oldSale.name === sale.name)
        if (sale.last != null) {
          sale.last.profits = sale.last.sales - sale.last.purchases
        } else {
          sale.last = {profits: 0, sales: 0, purchases: 0, flights: 0}
        }
        sale.profits = sale.sales - sale.purchases
      })
      return actual
    },
  },
}
</script>