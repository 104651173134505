<template>
  <v-card :elevation="1"
          style="height:100%; width:100%; background-color:white">
    <div class="d-flex flex-row justify-space-between pl-5 pt-5 pr-5">
      <div class="font-weight-bold text-lg">
        <slot name="title"> Default Title</slot>
      </div>
      <div v-if="nbTabs != 0">
        <div v-if="headersList != null && headersList.length > 0" class="d-flex ">
          <div v-for="(head,ind) in headersList" :key="head.text">
            <span v-if="ind > 0" class="mx-1">|</span>
            <span
                v-if="head.value !== 'name'"
                @click="changeView(head.text)"
                :class="
                headers[1].value === head.value
                  ? 'font-weight-black pointer-event-none'
                  : 'text-decoration-underline cursor-pointer'
              "
            >{{ $t(`dashboard.tableHeaders.${head.value}`) }}
            </span>
          </div>
        </div>
        <div v-else>
        <span
            @click="changeView('sales')"
            :class="
            headers[1].value == 'sales'
              ? 'font-weight-black '
              : 'text-decoration-underline cursor-pointer'
          "
        >Vente</span> |
          <span
              @click="changeView('purchases')"
              :class="
            headers[1].value == 'purchases'
              ? 'font-weight-black pointer-event-none'
              : 'text-decoration-underline cursor-pointer'
          "
          >Achat</span> |
          <span
              @click="changeView('profits')"
              :class="
            headers[1].value == 'profits'
              ? 'font-weight-black pointer-event-none'
              : 'text-decoration-underline cursor-pointer'
          "
          >Marge</span
          >
        </div>

      </div>
    </div>
    <v-row class="pa-5" style="height:100%;">
      <v-col cols="12" style="height:95%;">
        <v-text-field v-if="searchable"
                      v-model="search"
                      label="Search"
                      prepend-inner-icon="mdi-magnify"
                      single-line
                      variant="outlined"
                      hide-details
        ></v-text-field>
        <div style="height:90%;overflow-scrolling: auto; overflow:scroll">
          <v-data-table style=""
                        :items-per-page="1000"
                        :search="search"
                        :loading="items.length < 1 && loading"
                        :headers="headers"
                        :items="items"
                        :sort-by="[headers[1].value]"
                        :sort-desc="[true]"
                        search-field="name"
                        item-key="name"
          >
            <template #item.name="{item}">
              <div class="ellipsis">{{ item.name }}</div>
            </template>
            <template #[getCurrentSlotName]="{ item }">
              <v-row class="d-flex justify-end align-center text-xs">
                <div v-if="nbTabs === 0 && item.total" class="d-flex flex-row justify-space-between">
                  <span class="">
                    {{
                      item.isPrice == false ?
                          item[currentTarget]
                          : _formatCurrency(Math.round(item[currentTarget]), "EUR")
                    }}

                  </span>
                  <div style="width: 100px">
                    <v-chip
                        small
                        outlined
                        :color="
                    getDiffPercent(item[currentTarget], item.total) != null && getDiffPercent(item[currentTarget], item.total)[0] === '-'
                      ? (reverseChips ? 'green':'red')
                      : (reverseChips ? 'red':'green')
                  "
                    >
                      {{
                        item[currentTarget] != null ? getDiffPercent(item[currentTarget], item.total, true) : getDiffPercent(0, 0, true)
                      }}
                    </v-chip>
                  </div>
                </div>
                <div v-else class="d-flex flex-column">
                  <span>
                    {{
                      item.isPrice == false || getCurrentSlotName == 'item.count' ?
                          item[currentTarget] != null ? item[currentTarget] : '-'
                          : _formatCurrency(Math.round(item[currentTarget]), "EUR")
                    }}
                  </span>
                  <span class="caption grey--text lighten-1" v-if="item.last != null">
                    {{
                      item.last.isPrice == false || getCurrentSlotName == 'item.count' ?
                          item.last[currentTarget] != null ? item.last[currentTarget] : 0
                          : _formatCurrency(Math.round(item.last[currentTarget]), "EUR")
                    }}
                  </span>
                </div>
                <div style="width: 100px" v-if="item.last != null && comparable">
                  <v-chip
                      small
                      outlined
                      :color="
                    getDiffPercent(item[currentTarget], item.last[currentTarget]) != null && getDiffPercent(item[currentTarget], item.last[currentTarget])[0] === '-'
                      ? (reverseChips ? 'green':'red')
                      : (reverseChips ? 'red':'green')
                  "
                  >
                    {{
                      item[currentTarget] != null ? getDiffPercent(item[currentTarget], item.last[currentTarget], true) : getDiffPercent(0, 0, true)
                    }}
                  </v-chip>
                </div>
              </v-row>
            </template>
            <template #item.date="{ item }">
              <v-row class="d-flex items-center justify-end text-xs">
              <span>
                {{ _formatDate(new Date(item.date), "dd MMMM yyyy") }}</span>
                <div style="width: 100px">
                  <v-chip
                      small
                      outlined
                      :color="
                    item.evol[0] === '-'
                      ? (reverseChips ? 'green':'red')
                      : (reverseChips ? 'red':'green')
                  "
                  >
                    {{ item.evol }}
                  </v-chip>
                </div>
              </v-row>
            </template>
            <template #body.append>
              <tr class="sticky-table-footer flex-row justify-space-between font-weight-medium" v-if="showTotal">
                <td v-text="'Total'"/>
                <td class="text-right">
                  <div class="row d-flex justify-end text-xs"><span
                  >{{ getTotal.number }}</span>
                    <div style="width: 100px;">
                      <v-chip
                          small
                          outlined
                          :color="
                    getTotal.chips[0] === '-'
                      ? (reverseChips ? 'green':'red')
                      : (reverseChips ? 'red':'green')
                  "
                      >
                        {{ getTotal.chips }}
                      </v-chip>
                    </div>
                  </div>
                </td>

              </tr>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    items: {type: Array, required: true},
    nbHeaders: {type: Number, required: false, default: 3},
    headersList: {type: Array, required: false, default: Array.empty},
    defaultTarget: {type: String, required: false, default: "sales"},
    nbTabs: {type: Number, default: 3, required: false},
    reverseChips: {type: Boolean, required: false, default: false},
    showTotal: {type: Boolean, required: false, default: false},
    comparable: {type: Boolean, default: true},
    searchable: {type: Boolean, default: true},
  },
  data() {
    return {
      currentTarget: null,
      loading: true,
      search: "",
      headers: [
        {
          text: "Nom",
          value: "name",
          align: "left",
          sortable: true,
        },
        {},
      ],
    };
  },
  mounted() {
    this.changeView(this.defaultTarget)
  },
  created() {
    if (this.headersList != null && this.headersList.length == 1) {
      this.headers = [this.nameHead, this.headersList[0]]
    } else if (this.headersList != null && this.headersList.length == 2) {
      this.nameHead = this.headersList[0]
      this.headers = [this.headersList[0], this.headersList[1]]
    }

  },
  watch: {
    items: {
      immediate: true,
      handler(newVal) {
        if (newVal.length > 0 || newVal != null) {
          this.loading = false;
        }
      },
    },
  },
  computed: {
    getTotal() {
      let total = 0
      let oldTotal = 0
      let isPrice = null
      this.items.forEach((item) => {
        total += item[this.currentTarget] != null ? item[this.currentTarget] : 0
        oldTotal += item.last[this.currentTarget] != null ? item.last[this.currentTarget] : 0
      })
      isPrice = this.items.some(item => item.isPrice != false)

      return {
        number: isPrice ? this._formatCurrency(Math.round(total), "EUR") : this._formatDecimal(total),
        chips: this.getDiffPercent(total, oldTotal, true)
      }
    },
    getCurrentSlotName() {
      return 'item.' + this.currentTarget
    },

  },
  methods: {
    changeView(target) {
      this.currentTarget = target
      //this.$set force array refresh into props because of https://v2.vuejs.org/v2/guide/reactivity.html
      this.$set(this.headers, 1, {
        text: this.$t(`dashboard.tableHeaders.${target}`),
        value: target,
        align: "right",
        sortable: true,
      })

    },
    getDiffPercent(actual, old, rounded = false) {

      if (old === 0) {
        return this.comparable ? "+0%" : "O%"
      } else if (old == null) {
        return this.comparable ? "+∞%" : "-"
      }

      let percent;
      if (rounded) {
        percent = Math.round(this.comparable ? this._percentEvolution(old, actual) : this._percentage(old, actual))
      } else {
        percent = this._percentEvolution(old, actual)
      }

      const result = this._formatDecimal(percent) + "%";
      if (isNaN(percent)) {
        return null;
      }

      return result != null && result[0] == "-" ? result : this.comparable ? "+ " + result : result;
    }
  },
};
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.reverse {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.pointer-event-none {
  pointer-events: none;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 50px;
  max-width: 200px;
  display: block;
  overflow: hidden
}

</style>
