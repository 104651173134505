<template>
  <div>
    <v-row dense v-for="(segment, i) in fly.segments" :key="i">
      <v-col cols="12" class="ml-3">
        <v-row dense>
          <v-col>
            <div class="pt-1 pb-2 body-2 grey--text">
              {{ segment.marketingAirline.name }} ·
              {{ segment.passengerDetails && segment.passengerDetails.length > 0 ?
                segment.passengerDetails[0].travelClass
                : segment.travelClass }} ·
              {{ segment.airplane.maker }} {{ segment.airplane.model }}
              {{ segment.airplane.variant }} ·
              {{ segment.flightCode }}
            </div>
            <ul class="details-segment">
              <li>
                {{ flightTime(segment.departureDate) }} · {{ segment.from.name }} ({{
                  segment.from.code
                }})
                <small v-if="segment.from.terminal">Terminal {{ segment.from.terminal }}</small>
              </li>
              <li v-if="segment.flyStops && segment.flyStops.length > 0">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip color="red lighten-4" label x-small class="mr-1 px-1 py-3" v-on="on"
                      v-for="(s, sIndex) in segment.flyStops" :key="sIndex">
                      <v-icon small class="mr-2">mdi-timeline-clock-outline</v-icon> {{ s.airport.code }} {{
                        _renderDuration(s.duration) }}
                    </v-chip>
                  </template>
                  <span>Escale technique</span>
                </v-tooltip>
              </li>
              <li>
                Durée du trajet: {{ _renderDuration(segment.duration) }}
                <span v-if="
                  _nbNights(
                    _parseDate(segment.departureDate),
                    _parseDate(segment.arrivalDate)
                  ) > 0
                " class="red--text text--darken-1">
                  De nuit
                  <v-icon color="red darken-1" size="14">warning</v-icon>
                </span>
              </li>
              <li>
                {{ flightTime(segment.arrivalDate) }}
                <sup v-html="renderSupDays(segment)"></sup> · {{ segment.to.name }} ({{
                  segment.to.code
                }})
                <small v-if="segment.to.terminal">Terminal {{ segment.to.terminal }}</small>
              </li>
            </ul>
          </v-col>
          <v-col>
            <div v-if="segment.airplane.flightNumber && segment.airplane.airlinecode">
              <v-label>Flight Number:</v-label>
              {{ segment.airplane.airlinecode }}{{ segment.airplane.flightNumber }}
            </div>
            <div
              v-if="segment.passengerDetails && segment.passengerDetails.length > 0 && segment.passengerDetails[0].fareOption">
              <v-label>Fare Option:</v-label>
              {{ segment.passengerDetails[0].fareOption }}
            </div>

            <div
              v-if="segment.passengerDetails && segment.passengerDetails.length > 0 && segment.passengerDetails[0].fareBasis">
              <v-label>Fare Basis:</v-label>
              {{ segment.passengerDetails[0].fareBasis }}
            </div>
            <div v-if="segment.operatingAirline">
              <v-label>Operating Airline:</v-label>
              {{ segment.operatingAirline.code }}
            </div>
            <div v-if="segment.marketingAirline">
              <v-label>Marketing Airline:</v-label>
              {{ segment.marketingAirline.code }}
            </div>
            <div v-if="segment.validatingAirline">
              <v-label>Validating Airline:</v-label>
              {{ segment.validatingAirline.code }}
            </div>
          </v-col>
          <v-col cols="3" class="pt-3">

            <div v-for="(d, dIndex) in segmentDetails(segment)" :key="dIndex"
              class="d-flex justify-center align-center">
              {{ d.passengerType }}

              <template v-if="d.bags">

                <template v-if="d.bags.checked">
                  <v-icon class="ml-1">mdi-bag-checked</v-icon>
                  <template v-if="d.bags.checked.quantity > 0"> x{{d.bags.checked.quantity}}</template>
                  <template v-else-if="d.bags.checked.weight > 0">
                    {{ d.bags.checked.weight }}{{ d.bags.checked.weightUnit }}
                  </template>
                  <template v-else>0</template>
                </template>

                <template v-if="d.bags.cabin">
                  <v-icon class="ml-1">mdi-bag-carry-on</v-icon>
                  <template v-if="d.bags.cabin.quantity > 0"> x{{d.bags.cabin.quantity}}</template>
                  <template v-else-if="d.bags.cabin.weight > 0">
                    {{ d.bags.cabin.weight }}{{ d.bags.cabin.weightUnit }}
                  </template>
                  <template v-else>0</template>
                </template>

              </template>
              <template v-else-if="(d.luggages && d.luggages > 0) || (d.luggagesWeight && d.luggagesWeight > 0)">
                <v-icon size="18">mdi-wallet-travel</v-icon>
                <span v-if="d.luggages && d.luggages > 0">x{{ d.luggages }}</span>
                <span v-if="d.luggagesWeight && d.luggagesWeight > 0">{{ d.luggagesWeight }}kg</span>
                / par passager
              </template>
              <template v-else>
                <v-icon class="ml-1" color="red">mdi-bag-carry-on-off</v-icon>
              </template>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <div class="details-segment-flystops pl-2" v-if="fly.segments[i + 1]">

          {{
            _durationFormatted(
              _parseDate(segment.arrivalDate, datePatternConfig.serverLocalDateTime),
              _parseDate(fly.segments[i + 1].departureDate, datePatternConfig.serverLocalDateTime),
              datePatternConfig.fr.time
            )
          }}
          d'escale · {{ segment.to.name }} ({{ segment.to.code }})
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "FlightSegment",
  props: {
    fly: {
      type: Object,
      required: true,
    },
  },
  mixins: [],
  data: function () {
    return {};
  },
  created() { },
  watch: {},
  computed: {
  },
  methods: {
    renderSupDays(fly) {
      const nb = this._nbNights(
        this._parseDate(fly.departureDate),
        this._parseDate(fly.arrivalDate)
      );
      if (nb > 0) return "+" + nb;
      else "";
    },
    flightTime(date) {
      return date.substring(11, 16);
    },
    segmentDetails(segment) {
      return segment.details || segment.passengerDetails
    }
  },
};
</script>

<style scoped>
.row {
  color: #000000de !important;
}
</style>
