<template>
  <v-card :elevation="1"
          class="pa-4"
          style="height:100%; width:100%">
    <div class="font-weight-bold text-lg">
      {{ conf.title }}
    </div>
    <apexchart
        ref="apexcharts"
        type="donut"
        height="350"
        :options="options"
        :series="options.series"
        v-if="options.series.length > 0"
        class="my-auto"
    ></apexchart>
  </v-card>
</template>
<script>
import {EventBus} from "@/components/commons/event-bus";
import {post} from "@/utils/api";
import ColorMixin from "@/components/mixins/ColorMixin";
import {addYears, format, isValid} from "date-fns";

export default {
  name: "DonutStatPerVirtualSiteChart",
  mixins: [ColorMixin],
  data(vm) {
    return {
      salesTableUrl: `/api/widget/dashboard/sales/vsite`,
      options: {
        plotOptions: {
          pie: {
            customScale: 1,
            expandOnClick: true,
            donut: {
              size: '45%',
              labels: {
                show: false,
                name: {},
                value: {}
              }
            }
          }
        },
        series: [],
        labels: [],
        colors: [],
        legend: {
          position: 'top',
        },
        dataLabels: {
          enabled: true,
        },
        tooltip: {
          custom: {}
        }

      },
    }
  },
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}
  },
  created() {
    this.init()
    EventBus.$on('searchDashboard', () => this.init());
  },
  mounted() {
    this.options = {...this.options, ...this.conf.chartOptions}
    this.conf.chartOptions = this.options
  },
  methods: {
    init() {
      const searchForm = this._formatPayloadWidgetDashboard(this.conf, this.form)

      post(this.salesTableUrl, searchForm, {"Content-Type": "application/json"})
          .json(rs => {
            this.options.series = [];
            this.options.labels = [];
            this.options.colors = [];
            const sales = JSON.parse(JSON.stringify(rs.yearN))
            const serie = [];
            const labels = [];
            const colors = [];

            let total = 0
            for (let i = 0; i < sales.length; i++) {
              total += sales[i].sales
            }

            sales.forEach((sale) => {
              serie.push(sale.sales)
              labels.push(sale.name + " (" + Math.round(sale.sales*100/total)+"%)")
              colors.push(this.siteHexColor(sale.name))
            })
            this.options.series.splice(0, this.options.series.length - 1, ...serie)
            this.options.labels.splice(0, this.options.labels.length - 1, ...labels)
            this.options.colors.splice(0, this.options.colors.length - 1, ...colors)
            this.$nextTick(() => this.options = JSON.parse(JSON.stringify(this.options)))
          })
      const self = this
      this.options.tooltip.custom = function ({series, seriesIndex, dataPointIndex, w}) {
        const color = self.options.colors[seriesIndex]
        const value = self._formatCurrency(series[seriesIndex], 'EUR')

        return `<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;background-color: ${color};" >
                    <div class="apexcharts-tooltip-text" style="font-size: 12px;">
                      <div class="apexcharts-tooltip-y-group d-flex flex-row justify-space-between">
                        <span class="apexcharts-tooltip-text-y-label">${w.config.labels[seriesIndex]} </span>
                        <span class="apexcharts-tooltip-text-y-value">${value}</span>
                      </div>
                    </div>
                  </div>`
      }


    },
  }
}
</script>