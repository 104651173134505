<template>
  <v-card ref="vCardRef" :elevation="1" style="height:100%; width:100%; overflow: hidden " class="pa-4">
  <div class="font-weight-bold text-lg mb-10">
      {{conf.title }}
    </div>
    <apexchart
        type="area"
        :height="series.length > 0 ? chartHeight : null"
        :options="options"
        :series="series"
        v-if="series.length > 0"
        style="height:auto"
    ></apexchart>
  </v-card>
</template>
<script>
import {post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";
import {ref} from "vue";
//État des stocks dans les 6 prochains mois
export default {
  name: "StockStatusFutureChart",
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}
  },

  data() {
    return {
      vCardRef:ref(null),
      data: [],
      url: `/api/widget/dashboard/stock/status`,
      groups: [
        {name: "Stock+FreeSale", ids: [2, 4], color: "#00E396"},
        {name: "Request", ids: [1], color: "#FEB019"},
        {name: "Closed+StopSell", ids: [3, 7], color: "#FF4560"}
      ],
      options: {
        chart: {
          toolbar:{
          show:false
        },
          type: "area",
          stacked: true,
          height:null,
        },

        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        fill: {
          opacity: 1,
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.45,
            opacityTo: 0.05
          }
        },
        colors: [],
        legend: {
          position: "top",
          horizontalAlign: "left"
        },
        xaxis: {
          type: "datetime"
        }
      },
    }
  },
  created() {
    EventBus.$on('editMode', () => this.updateSize())
    EventBus.$on('searchDashboard', () => this.init());
    this.init();
  },
  computed: {
    chartHeight() {
      return this.vCardRef != null ? `${this.$refs.vCardRef.$el.clientHeight-100}px` : 'auto';
    },
    grouped() {
      return Object.groupBy(this.data, (d) => {
        return d.date;
      })
    },
    labels() {
      return Object.keys(this.grouped)
    },
    series() {
      return this.groups.map((g) => {
        const dates = Object.keys(this.grouped);

        const values = dates.map((key) => {
          const total = this.grouped[key].reduce((total, item) => {
            if (g.ids.indexOf(item.id) !== -1) {
              return total + item.count;
            }
            return total;
          }, 0);
          return {
            x: key,
            y: total
          };
        })
        return {
          color: g.color,
          name: g.name,
          data: values
        }
      })
    }
  },
  mounted(){
    this.vCardRef = this.$refs.vCardRef.$el.clientHeight
    if(this.conf.chartOptions == null){
          this.conf.chartOptions = this.options
        delete this.conf.chartOptions.xaxis.min
        delete this.conf.chartOptions.xaxis.max
      } else {
        this.options = {...this.options, ...this.conf.chartOptions}
      }
  },

  methods: {
    updateSize(){
      this.vCardRef = this.$refs.vCardRef != null ? this.$refs.vCardRef.$el.clientHeight : 0
    },
    init() {
      let periods = null
      if(this.conf.data.act.type != null && this.conf.data.old.type != null){
        periods = {act:this._calculateDates(this.conf.data.act),old:this._calculateDates(this.conf.data.old)}
      }
      const formProps = JSON.parse(JSON.stringify(this.form))
      const searchForm = {
        startDateAct: periods != null ? periods.act.start : formProps.startDateAct,
        stopDateAct: periods != null ? periods.act.stop : formProps.stopDateAct,
        startDateOld: periods != null ? periods.old.start : formProps.startDateOld,
        stopDateOld: periods != null ? periods.old.stop : formProps.stopDateOld,
        destinations: this.conf.data.destinations.length > 0 ? this.conf.data.destinations : formProps.destinations,
        sites: this.conf.data.sites.length > 0 ? this.conf.data.sites : formProps.sites,
        commercialIds:[],
        allAgencies: false
      }
      post(this.url, searchForm, {"Content-Type": "application/json"})
          .json(rs => {
            this.data = rs
          })
    },
  }
}
</script>